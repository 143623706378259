import removeAccents from '@nubank/nuds-web/utils/string/removeAccents';

const forbiddenCharsRegex = /[Ñ]/g;

export function firstVowelExcludingFirstCharacter(word) {
  const result = word.slice(1).match(/[aeiou]/gi);
  return result ? result[0] : 'X';
}

export function standardizeString(str) {
  return removeAccents(str.replace(forbiddenCharsRegex, 'X'))
    .toUpperCase()
    .trim();
}

export function removeSpecialChars(str) {
  const speaclCharactersRegex = /[\d_\-./\\,]/g;
  return str.replace(speaclCharactersRegex, 'X');
}

export function getFilteredCompoundName(names) {
  if (names.split(' ').length > 1) {
    return names.replace(/^(JOSE|J|J\.|MARIA|MA|MA\.)\s+/i, '');
  }

  return names;
}

export function getFilteredCompoundLastName(str) {
  if (str.split(' ').length > 1) {
    return str.replace(/\b( ?DA|DAS|DE|DEL|DER|DI|DIE|DD|EL|LA|LOS|LAS|LE|LES|MAC|MC|VAN|VON, Y)\b\s+/g, '');
  }

  return str;
}

export function filterInconvenientWords(str) {
  const match = str.match(
    /(BA[KC]A|BUE[IY]|CAC[AO]|CAG[AO]|CAK[AO]|COG[EI]|COJ[AEIO]|COLA|CULO|FALO|FETO|GETA|GUE[IY]|JETA|JOTO|KAC[AO]|KAG[AO]|KAK[AO]|KOG[EI]|KOJ[AEIO]|KOLA|KULO|LILO|LOC[AO]|LOK[AO]|MAM[EO]|MEAR|MEAS|MEON|MIAR|MION|MO[CK]O|MUL[AO]|NAC[AO]|PEDA|PEDO|PENE|PIPI|PITO|POPO|PUT[AO]|QULO|RATA|ROB[AEO]|RUIN|SENO|TETA|VA[CGK]A|VAGO|VUE[IY]|WUE[IY])/,
  );

  if (match) {
    return str.replace(/^(\w)\w/, '$1X');
  }

  return str;
}

export function getFirstConsonant(str) {
  const cleanStr = str.slice(1, str.length);
  const consonants = cleanStr.match(/[^aeiou]/gi);
  return consonants ? consonants[0] : 'X';
}
