import React from 'react';
import styled from 'styled-components';
import Button from '@nubank/nuds-web/components/Button/Button';
import PropTypes from 'prop-types';
import Image from '@nubank/nuds-web/components/Image/Image';

import Spinner from '../../../../components/Spinner/Spinner';

const StyledBox = styled.div` 
  width: 100%;
  min-height: 56px;
  position: relative;
  border-bottom: 1px solid ${props => (!props.isValid ? '#EFEFEF' : 'red')};
  font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
  margin-top: 44px;
  display: flex;

  input {
    width: 100%;
    border: none;
    outline: none;
    padding: 12px 0;
    color: #000000F5;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 24px */
    letter-spacing: -0.4px;
    font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
    padding-bottom: 12px;
    background-color: transparent;
  }

  & label {
    position: absolute;
    left: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; /* 24px */
    letter-spacing: -0.4px;
    top: 12px;
    pointer-events: none;
    transition: 0.2s;
    color: #00000052;
    font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
  }


  input:focus + label, input:not(:placeholder-shown) + label {
    top: -15px;
    color: #000000A3;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.14px;
    caret-color: #820AD1;
    font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
  }


  button {
    min-width: fit-content;
    padding-left: .7rem;
    padding-right: .7rem;
    margin-bottom: 8px;
  }

  picture {
    display: flex;
    align-items: center;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 12px;
  position: absolute;
  top: 60px;
`;

const StyledImage = styled(Image)`
  min-width: 20px;
`;

function TextInput({
  type,
  label,
  name,
  value,
  showButton,
  isRequired,
  onChange,
  maxLength,
  buttonAction,
  buttonText,
  error,
  errorMessage,
  isLoading,
  isDisabled,
  showCounter,
  id,
}) {
  return (
    <StyledBox isValid={error}>
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        required={isRequired}
        onChange={onChange}
        placeholder=" "
        maxLength={maxLength}
        disabled={isLoading || isDisabled}
      />
      <label htmlFor={name}>
        {label}
        {' '}
        {showCounter && value && `${value.length}/${maxLength}`}
      </label>

      {isLoading && (
      <Spinner color="#820AD1" />
      )}

      {/* CUSTOM BTN */}
      {(showButton && !isLoading) && (
        <Button
          variant="basic"
          type="button"
          onClick={buttonAction}
        >
          {buttonText}
        </Button>
      )}

      {isDisabled && (
      <Image
        alt="Icono de un candado"
        src="one-step/lock.svg"
        webp={false}
      />
      )}

      {/* ERROR ICON */}
      {error && (
        <StyledImage
          alt="icono error"
          src="one-step/warning_circle.svg"
          webp={false}
          height="22px"
        />
      )}

      {/* ERROR MESSAGE */}
      { error && (
      <ErrorMessage>
        {errorMessage}
      </ErrorMessage>
      )}
    </StyledBox>
  );
}

export default TextInput;

TextInput.defaultProps = {
  isRequired: false,
  showButton: false,
  type: 'text',
  maxLength: 100,
  buttonAction: () => {},
  error: false,
  errorMessage: 'No se permiten caracteres especiales ($#*&@)',
  isLoading: false,
  isDisabled: false,
  showCounter: false,
  buttonText: 'Encontrar mi Código Postal',
};

TextInput.propTypes = {
  buttonAction: PropTypes.func,
  buttonText: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showButton: PropTypes.bool,
  showCounter: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};
