import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useRegistrationFormContext } from '../../RegistrationForm/RegistrationForm';

import { StyledCheckbox } from './StyledCheckbox';

const CheckboxFeedback = ({
  setFieldValue,
}) => {
  const {
    uncheckPersonalInfoBox, setUncheckPersonalInfoBox,
  } = useRegistrationFormContext();

  useEffect(() => {
    if (uncheckPersonalInfoBox) {
      setFieldValue('accepted', false);
      setUncheckPersonalInfoBox(false);
    }
  }, [uncheckPersonalInfoBox]);

  return (
    <StyledCheckbox
      id="field-accepted"
      name="accepted"
      label="Mis datos son correctos."
      syncValidations={{
        required: 'No olvides confirmar que tus datos son correctos',
      }}
    />
  );
};

CheckboxFeedback.propTypes = {
  currentStepValues: PropTypes.shape({
    accepted: PropTypes.bool,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CheckboxFeedback;
