const HOMONIMIA_DEFAULT_VALUE = '0';
const HOMONIMIA_RECENT_VALUE = 'A';

export function getHomonimia(dob) {
  const [,, year] = dob.split('/');

  if (Number(year) > 1999) {
    return HOMONIMIA_RECENT_VALUE;
  }

  return HOMONIMIA_DEFAULT_VALUE;
}

