import React, { useCallback } from 'react';
import styled from 'styled-components';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import PropTypes from 'prop-types';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Snackbar from '@nubank/nuds-web/components/Snackbar/Snackbar';

import { useSiteContext } from '../../../../components/SiteContext/SiteContext';
import { ERROR_SEVERITY, sentryException } from '../../../../utils/sentry';
import { createAuthorizationRequest } from '../../../../domains/prospect/bureauAuthorizationRequest';

const StyledDrawer = styled(Drawer)`
  #phone-edit-drawer {
    bottom: 0;
    top: auto;
    height: 360px;
    border-radius: 16px 16px 0 0;
    padding: 24px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  max-width: 340px;
  justify-content: center;
  margin: 0 auto;

  svg {
    margin-top: 8px;
    margin-left: 12px;
  }
`;

const StyledTextField = styled(TextField)`
    max-width: 340px;
    min-width: 280px;

    @media (width >= 768px) {
      min-width: 340px;
    }
`;

const StyledTextPlaceholder = styled(Typography)`
  font-size: 1.125rem;
`;

const NOT_A_NUMBER_REGEX = /\D/g;

function PhoneEditDrawer({ setShowPhoneEditModal, showPhoneEditModal }) {
  const {
    setPhone, phone, setAuthorizationRequest, discoveryUrlsList,
  } = useSiteContext();
  const [newPhone, setNewPhone] = React.useState('');
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [formErrorMsg, setFormErrorMsg] = React.useState('');

  const clearFormErrorMsg = () => setFormErrorMsg('');

  const inputMask = useCallback(value => {
    const valueWithOnlyNumbers = value.replace(NOT_A_NUMBER_REGEX, '');
    let maskedValue = valueWithOnlyNumbers;

    if (value.length >= 3) {
      maskedValue = `${maskedValue.substring(0, 2)} ${maskedValue.substring(2, maskedValue.length)}`;

      if (value.length >= 8) {
        maskedValue = `${maskedValue.substring(0, 7)} ${maskedValue.substring(7, maskedValue.length)}`;
      }
    }
    return maskedValue.trim();
  }, []);

  const handleSubmit = async () => {
    setSubmitting(true);

    if (phone === newPhone) {
      setFormErrorMsg('El número de teléfono ingresado es el mismo que el actual.');
      setSubmitting(false);
      return;
    }

    try {
      // Resend the SMS OTP and overwrite values
      const authorizationRequestValues = await createAuthorizationRequest({
        phone: newPhone,
        urls: discoveryUrlsList,
        includeOriginURL: false,
      });

      setAuthorizationRequest({
        id: authorizationRequestValues.id,
        flowId: authorizationRequestValues.flow_id,
      });

      // Save phone in context
      setPhone(newPhone);

      // reset form in case of success
      setNewPhone('');
      setShowPhoneEditModal(false);
    } catch (error) {
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'setAuthorizationRequest from edit phone drawer',
        namespace: '<PhoneEditDrawer />',
        level: ERROR_SEVERITY.CRITICAL,
      });

      setFormErrorMsg('Sucedió un error inesperado. Por favor espera un momento e intenta nuevamente. Si el problema persiste contáctanos.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleOnChange = ev => {
    const { target: { value } } = ev;
    const maskedValue = inputMask(value);
    setNewPhone(maskedValue);
  };

  return (
    <StyledDrawer
      width="100%"
      id="phone-edit-drawer"
      direction="bottom"
      desktopDirection="bottom"
      open={showPhoneEditModal}
      onClose={() => setShowPhoneEditModal(false)}
    >
      {({ DrawerContent, DrawerCloseButton }) => (
        <DrawerContent>
          <DrawerCloseButton onClick={() => setShowPhoneEditModal(false)} />

          <Typography
            marginBottom="16px"
            marginTop="16px"
            variant="heading4"
          >
            ¿Ese no es tu celular? 🤔
          </Typography>

          <Typography color="#000000A3">
            Déjanos tu número para actualizarlo y  enviarte el código.
          </Typography>

          <Box
            display="flex"
            marginBottom="16px"
            width="100%"
          >
            <Box
              display="flex"
              height="32px"
              marginTop="1.25rem"
              alignItems="center"
              padding="2x"
            >
              <StyledTextPlaceholder
                variant="paragraph1"
                strong
              >
                +52
              </StyledTextPlaceholder>
            </Box>

            <StyledTextField
              id="newPhone"
              name="newPhone"
              placeholder="00-0000-0000"
              type="tel"
              onChange={handleOnChange}
              maxLength="12"
              required
              value={newPhone}
            />

          </Box>

          <StyledButton
            id="new-phone-edit-submit-btn"
            type="button"
            variant="contained"
            styleVariant="primary"
            disabled={isSubmitting || newPhone.length < 12}
            iconProps={{ name: 'check' }}
            extended
            onClick={handleSubmit}
          >
            Actualizar número
          </StyledButton>

          <Snackbar
            visible={Boolean(formErrorMsg)}
            onActionClick={clearFormErrorMsg}
            actionText="Cerrar"
          >
            {formErrorMsg}
          </Snackbar>

        </DrawerContent>
      )}
    </StyledDrawer>
  );
}

PhoneEditDrawer.propTypes = {
  setShowPhoneEditModal: PropTypes.func.isRequired,
  showPhoneEditModal: PropTypes.bool.isRequired,
};

export default PhoneEditDrawer;
