const MX_MALE_GENDER = 'MALE';
const MX_FEMALE_GENDER = 'FEMALE';
const MX_NO_BINARY = 'NON-BINARY';
const CURP_MALE_CODE = 'H';
const CURP_FEMALE_CODE = 'M';
const CURP_NO_BINARY = 'X';

// Value can be H, M, female, male or non-binary strings
export const mapGenderValue = value => {
  const upperValue = value.toUpperCase();

  if (upperValue === CURP_MALE_CODE || upperValue === MX_MALE_GENDER) {
    return 'male';
  } if (upperValue === CURP_FEMALE_CODE || upperValue === MX_FEMALE_GENDER) {
    return 'female';
  } if (upperValue === CURP_NO_BINARY || upperValue === MX_NO_BINARY) {
    return 'non-binary';
  }
  return 'female'; // Default case if none match
};
