import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Image from '@nubank/nuds-web/components/Image/Image';

import ProgressBar from '../../../../components/ProgressBar/ProgressBar';

import { LoadingScreenContainer } from './styles/LoadingScreenContainer';

const LoadingScreen = ({ percentage }) => (
  <LoadingScreenContainer>
    <Image
      src="one-step/loading-figures.svg"
      alt="Figuras geometricas color moradas como animacion de carga"
      webp={false}
      width="150px"
    />

    <Typography
      variant="heading2"
      color="black"
      colorVariant="light"
      intlKey="PROSPECT_REGISTRATION_FORM.DECISION_STEP.LOADING_MESSAGE"
      marginBottom="6x"
      marginTop="8x"
    />

    <ProgressBar percentage={percentage} height="0.375rem" borderRadius="6" />
  </LoadingScreenContainer>
);

LoadingScreen.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default LoadingScreen;
