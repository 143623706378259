import styled from 'styled-components';

export const DeniedScreenContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  img {
    margin: auto 0;
  }

  p {
    white-space: pre-wrap;

    &::first-letter {
      text-transform: capitalize;
    }
  }
  
  strong {
    font-weight: bold;
  }
`;

DeniedScreenContainer.displayName = 'DeniedScreenContainer';
