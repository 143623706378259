import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TooltipContainer = styled.div`
  position: relative;
`;

const TooltipText = styled.div`
  position: absolute;
  background-color: black;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  border-radius: 4px;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  display: ${props => (props.visible ? 'block' : 'none')};
  z-index: 2;
  text-align: center;
  font-size: 14px;
`;

const Triangle = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333;
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <TooltipContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Triangle visible={showTooltip} />
      <TooltipText visible={showTooltip}>{text}</TooltipText>
      {children}
    </TooltipContainer>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

export default Tooltip;
