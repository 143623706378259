import styled from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import TextField from '@nubank/nuds-web/components/TextField/TextField';

export const StyledTitle = styled(Typography)`
  font-size: 2rem;

  @media (width >= 768px) {
    font-size: 2.5rem;
  }
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 18px;

  span {
    font-weight: bold;
  }
`;

export const StyledSection = styled(Box)`
  button {
    min-width: 64px;
    height: 64px;
  }
`;

export const StyledTextButton = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`;

export const StyledButton = styled.button`
  background-color: transparent;
  cursor: pointer;

  :hover {
    opacity: .8;
  }

  :disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .5;
  }
`;

export const StyledPhoneTypography = styled(Typography)`
  font-size: 20px;
`;

export const StyledTextField = styled(TextField)`
  margin-top: 24px;
`;

