import React from 'react';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import Box from '@nubank/nuds-web/components/Box/Box';

import WebsiteProvider from '@nubank/www-latam-commons/components/WebsiteProvider/WebsiteProvider';
import RouterLink from 'components/RouterLink/RouterLink';

import { useSiteContext } from '../../components/SiteContext/SiteContext';
import WebsiteSEO from '../../components/WebsiteSEO/WebsiteSEO';
import esMXLocalMessages from '../../locale/es-MX.json';
import HeaderXP from '../../components/HeaderXP/HeaderXP';
import Footer from '../../components/FooterXP/Footer';
import { images } from '../../utils/seo';
import RegistrationFormWithCURP from '../../screens/Registration/RegistrationFormWithCURP';
import MenuWrapper from '../../components/HeaderXP/MenuWrapper/MenuWrapper';

export const HEADER_TYPE_WITH_MENU_AND_APPLICATION = 'WITH_MENU_AND_APPLICATION';
export const HEADER_TYPE_NO_MENU_NO_APPLICATION = 'NO_MENU_NO_APPLICATION';
export const HEADER_TYPE_NONE = 'HEADER_TYPE_NONE';

export const FOOTER_TYPE_FULL = 'FOOTER_TYPE_FULL';
export const FOOTER_TYPE_NO_NAVIGATION = 'FOOTER_TYPE_NO_NAVIGATION';
export const FOOTER_TYPE_NONE = 'FOOTER_TYPE_NONE';

const customPolyfills = [
  'Intl',
];

const websitePage = (WrappedComponent, options = {}) => {
  const hocOptions = {
    header: HEADER_TYPE_WITH_MENU_AND_APPLICATION,
    footer: FOOTER_TYPE_FULL,
    routeKey: 'HOME',
    customHeaderCTA: undefined,
    seoComponent: WebsiteSEO,
    allowApplicationForm: true,
    isWhiteColorHero: true,
    buttonLabel: 'HOME.MULTIPRODUCT.HERO.CTA.BTN',
    btnVisibleScrolling: undefined,
    customSEOImages: false,
    ...options,
  };

  const WebsitePageComponent = props => {
    // eslint-disable-next-line react/prop-types
    const { cmsMessages } = props;
    const {
      seoComponent: SEOComponent,
      routeKey,
      allowApplicationForm,
      header,
      footer,
    } = hocOptions;

    const {
      isRegistrationFormOpen,
      closeRegistrationForm,
    } = useSiteContext();

    const hideCTAButton = !allowApplicationForm || [
      HEADER_TYPE_NO_MENU_NO_APPLICATION,
    ].includes(header);

    const hideFooterNavigation = footer === FOOTER_TYPE_NO_NAVIGATION;
    const showHeader = header !== HEADER_TYPE_NONE;
    const showFooter = footer !== FOOTER_TYPE_NONE;

    const SEOimages = hocOptions.customSEOImages?.length >= 1 ? hocOptions.customSEOImages : images;

    return (
      <WebsiteProvider
        locale={process.env.NEXT_PUBLIC_WWW_LOCALE}
        messages={{ ...esMXLocalMessages, cms: cmsMessages }}
        routerLinkComponent={RouterLink}
        customPolyfillFeatures={customPolyfills}
        enablePolyfill={false}
      >
        <SEOComponent routeKey={routeKey} images={SEOimages} />

        {showHeader && (
        <HeaderXP>
          <MenuWrapper
            hideCTAButton={hideCTAButton}
            customHeaderCTA={hocOptions.customHeaderCTA}
            isWhiteColorHero={hocOptions.isWhiteColorHero}
            buttonLabel={hocOptions.buttonLabel}
            btnVisibleScrolling={hocOptions.btnVisibleScrolling}
          />
        </HeaderXP>
        )}

        <Box
          id="main-content"
          display="flex"
          flexDirection="column"
          flex="1"
        >
          {
            allowApplicationForm && (
            <Drawer
              id="prospect-registration-form"
              onClose={closeRegistrationForm}
              open={isRegistrationFormOpen}
            >
              {({ DrawerContent }) => (
                <DrawerContent>
                  <RegistrationFormWithCURP />
                </DrawerContent>
              )}
            </Drawer>
            )
          }
          <WrappedComponent {...props} />
        </Box>

        {showFooter && <Footer hideFooterNavigation={hideFooterNavigation} />}

      </WebsiteProvider>

    );
  };

  if (WrappedComponent.getInitialProps) {
    WebsitePageComponent.getInitialProps = async cyx => {
      const pageProps = await WrappedComponent.getInitialProps(cyx);

      return {
        ...pageProps,
      };
    };
  }

  return WebsitePageComponent;
};

export default websitePage;
