import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Button from '@nubank/nuds-web/components/Button/Button';
import PropTypes from 'prop-types';

import { getGeoStatesList } from '../../../../domains/address/states';
import { getGeoCitiesList } from '../../../../domains/address/city';
import { getGeoLocalitiesList } from '../../../../domains/address/locality';
import statesOptions from '../../../../utils/form/states';
import { useSiteContext } from '../../../../components/SiteContext/SiteContext';

import CustomSelectField from './CustomSelectField';

const StyledSubtitle = styled(Typography)`
  span {
    color: #820AD1;
    font-weight: bold;
  }
`;

const StyledSection = styled(Box)`
  button {
    min-width: 64px;
    min-height: 64px;
    margin-left: 18px;
  }
`;

function UserAddressSearchForm({
  showMainAddressScreen,
  setFormData,
  setValidations,
  setShowManualButton,
  setCityOptions,
  setLocalityOptions,
}) {
  const [searchInputsData, setSearchInputsData] = useState({
    state: '',
    city: '',
    locality: '',
  });

  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [localitiesList, setLocalitiesList] = useState([]);
  const [isLoadingStates, setIsLoadingStates] = useState(true);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [isLoadingLocalities, setIsLoadingLocalities] = useState(false);
  const { discoveryUrlsList } = useSiteContext();

  const getStateCodeFromStateName = stateName => {
    const state = statesOptions.find(stateObject => stateObject.label === stateName);
    return state?.value;
  };

  /// ////////////////STATE//////////////////////
  const handleStateSelectChange = async (name, value) => {
    if (value === '') {
      setSearchInputsData({
        state: '',
        city: '',
        locality: '',
      });

      return;
    }

    setSearchInputsData(prevData => ({
      ...prevData,
      state: value,
      city: '',
      locality: '',
    }));

    setIsLoadingCities(true);

    // get cities from selected state
    const { cities } = await getGeoCitiesList(value, discoveryUrlsList);
    // map and set cities list in state (label and value )
    const list = cities.map(city => ({
      label: city,
      value: city,
    })).sort((a, b) => a.label.localeCompare(b.label));

    setCitiesList(list);
    setIsLoadingCities(false);
  };

  /// ////////CITY//////////////////
  const handleCitySelectChange = async (name, value) => {
    if (value === '') {
      return;
    }

    setSearchInputsData(prevData => ({
      ...prevData,
      city: value,
      locality: '',
    }));

    setIsLoadingLocalities(true);

    // get localities from selected city and state
    const { regions } = await getGeoLocalitiesList(
      searchInputsData.state,
      value,
      discoveryUrlsList,
    );

    // map and set localities list in state (label and value),
    // i added the postcode to the value separated with the string '-+-'
    const list = regions.flatMap(
      ({ postcode, localities }) => localities.map(
        locality => ({ value: `${locality}-+-${postcode}`, label: locality }),
      ),
    ).sort((a, b) => a.label.localeCompare(b.label));

    setLocalitiesList(list);
    setIsLoadingLocalities(false);
  };

  /// /////////////LOCALITY///////////////////////
  const handleLocalitySelectChange = (name, value) => {
    setSearchInputsData(prevData => ({
      ...prevData,
      locality: value,
    }));
  };

  const handleSubmitButton = async () => {
    const cleanLocalityValue = searchInputsData.locality.split('-+-')[0];
    const postCode = searchInputsData.locality.split('-+-')[1];

    // set address in form from main screen
    setFormData({
      postCode,
      addressState: getStateCodeFromStateName(searchInputsData.state),
      city: searchInputsData.city,
      locality: cleanLocalityValue,
      street: '',
      streetNumberExt: '',
      streetNumberInt: '',
      localityManualInput: '',
      prospectUsedManualInputs: true,
    });

    setCityOptions([{
      label: searchInputsData.city, value: searchInputsData.city,
    }]);

    setLocalityOptions([{
      label: cleanLocalityValue,
      value: cleanLocalityValue,
    }]);

    // (hide "encontrar codigo" button and disable postcode field)
    setShowManualButton(false);

    // reset validations
    setValidations({
      postCode: true,
      addressState: true,
      city: true,
      locality: true,
      street: false,
      streetNumberExt: false,
      streetNumberInt: true,
    });

    // return and show main address screen
    showMainAddressScreen();
  };
  useEffect(async () => {
    const fetchStatesData = async () => {
      try {
        const { states } = await getGeoStatesList(discoveryUrlsList);
        // states is an array of strings, we need to map it to an array of objects
        const list = states.map(state => ({
          label: state,
          value: state,
        })).sort((a, b) => a.label.localeCompare(b.label));

        setStatesList(list);
      } catch (error) {
        console.error('Error fetching states data:', error);
      } finally {
        setIsLoadingStates(false);
      }
    };

    fetchStatesData();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      flex="1 0 100%"
    >
      <Box>
        <Typography
          variant="heading3"
          marginBottom="12px"
        >
          Encontremos tu Código Postal 👀
        </Typography>

        <StyledSubtitle>
          Selecciona tus datos
          {' '}
          <span>¡nosotros nos encargamos del resto!</span>
        </StyledSubtitle>

        {/* STATE */}

        <CustomSelectField
          name="state"
          externalValue={searchInputsData.state}
          placeholder="Estado"
          isDisabled={statesList.length === 0}
          options={statesList}
          onChange={handleStateSelectChange}
          isLoading={isLoadingStates}
        />

        {/* CITY */}

        <CustomSelectField
          name="city"
          externalValue={searchInputsData.city}
          placeholder="Alcaldía o Municipio"
          isDisabled={searchInputsData.state === '' || citiesList.length === 0}
          options={citiesList}
          onChange={handleCitySelectChange}
          isLoading={isLoadingCities}
        />

        {/* LOCALITY */}

        <CustomSelectField
          name="locality"
          externalValue={searchInputsData.locality}
          placeholder="Colonia o Asentamiento"
          onChange={handleLocalitySelectChange}
          options={localitiesList}
          isDisabled={searchInputsData.city === '' || searchInputsData.state === '' || localitiesList.length === 0}
          isLoading={isLoadingLocalities}
        />
      </Box>

      <StyledSection display="flex" alignItems="center">
        <StyledSubtitle variant="paragraph1" marginRight="8x">
          <span>Usa los mismos datos que tienes en tus recibos </span>
          de luz o teléfono.
        </StyledSubtitle>

        <Button
          variant="contained"
          styleVariant="primary"
          iconProps={{ name: 'arrow-right', title: 'Buscar Código Postal' }}
          disabled={searchInputsData.locality === '' || searchInputsData.city === '' || searchInputsData.state === ''}
          onClick={handleSubmitButton}
        />
      </StyledSection>
    </Box>
  );
}

UserAddressSearchForm.propTypes = {
  setCityOptions: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  setLocalityOptions: PropTypes.func.isRequired,
  setShowManualButton: PropTypes.func.isRequired,
  setValidations: PropTypes.func.isRequired,
  showMainAddressScreen: PropTypes.func.isRequired,
};

export default UserAddressSearchForm;
