import styled from 'styled-components';
import Checkbox from '@nubank/nuds-web/components/Checkbox/Checkbox';

export const StyledCheckbox = styled(Checkbox)`
  padding-bottom: 1.5rem;

  span {
    background-color: transparent;
  }
`;

StyledCheckbox.displayName = 'StyledCheckbox';
