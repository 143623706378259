import React from 'react';
import RadioButtonGroup from '@nubank/nuds-web/components/RadioButtonGroup/RadioButtonGroup';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "label label" "female male";
  grid-row-gap: 0;

  legend {
    grid-area: label;
  }

  label:first-of-type {
    grid-area: female;
    align-self: center;
  }

  label:last-of-type {
    grid-area: male;
    align-self: center;
  }
`;

const genderOptions = [{
  value: 'M',
  label: 'Mujer',
}, {
  value: 'H',
  label: 'Hombre',
}];

const GenderInput = () => {
  const { formatMessage } = useIntl();

  if (genderOptions[0].value !== 'M' || genderOptions[1].value !== 'H') {
    throw new Error('GenderInput must be used only with M (as first) and H (as second) values, if you need a similar input you should create a new one');
  }

  return (
    <>
      <StyledRadioButtonGroup
        label={formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CURP_XP.GENDER.LABEL' })}
        id="gender"
        name="gender"
        options={genderOptions}
        syncValidations={{
          required: formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP.CURP_XP.GENDER.VALIDATION.MESSAGE' }),
        }}
      />

    </>
  );
};

export default GenderInput;
