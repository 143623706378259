import isEmpty from '@nubank/nuds-web/utils/string/isEmpty';

import {
  firstVowelExcludingFirstCharacter,
  filterInconvenientWords,
  removeSpecialChars,
} from './helpers';
import { normalizeNamesAndLastnames } from './normalize';

export function createNameCode(names, firstSurname, secondSurname) {
  const {
    normalizedNames,
    normalizedFirstSurname,
    normalizedSecondSurname,
  } = normalizeNamesAndLastnames(names, firstSurname, secondSurname);

  const firstChar = normalizedFirstSurname[0];
  const secondChar = firstVowelExcludingFirstCharacter(normalizedFirstSurname);
  let thirdChar = '';

  if (!normalizedSecondSurname || isEmpty(normalizedSecondSurname)) {
    thirdChar = 'X';
  } else {
    thirdChar = normalizedSecondSurname.substring(0, 1);
  }

  const fourthChar = normalizedNames[0];
  const cleanChars = removeSpecialChars(firstChar + secondChar + thirdChar + fourthChar);

  return filterInconvenientWords(cleanChars);
}
