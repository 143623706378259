import React from 'react';
import PropTypes from 'prop-types';
import Image from '@nubank/nuds-web/components/Image/Image';

import Spinner from '../../../../components/Spinner/Spinner';

import ToggleButton from './ToggleButton';

function ShowIcon({
  isDisabled, isOpen, isError, isLoading,
}) {
  if (isLoading) {
    return <Spinner color="#820AD1" />;
  }

  if (isDisabled) {
    return (
      <Image
        alt="Icono de un candado"
        src="one-step/lock.svg"
        webp={false}
      />
    );
  }

  if (isError) {
    return (
      <Image
        alt="Icono de error"
        src="one-step/warning_circle.svg"
        webp={false}
      />
    );
  }

  return <ToggleButton isOpen={isOpen} />;
}

ShowIcon.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ShowIcon;
