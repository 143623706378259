import isEmpty from '@nubank/nuds-web/utils/string/isEmpty';

import {
  firstVowelExcludingFirstCharacterOf,
  standardizeString,
  getFilteredPersonName,
  isFirstLastNameIsTooShort,
  obfuscateForbiddenWords,
} from './rfcHelpers';
import calculateHomoclave from './homoclave';
import calculateDigit from './verificationDigit';

export function getBirthdayCode(dob) {
  const [day, month, year] = dob.split('/');
  return year.slice(-2) + month + day;
}

export function getNameCode(names, firstSurname, secondSurname) {
  const normalizedNames = standardizeString(names);
  const normalizedFirstSurname = standardizeString(firstSurname);
  const normalizedSecondSurname = standardizeString(secondSurname);

  if (isEmpty(normalizedFirstSurname)) {
    return (
      normalizedSecondSurname.substring(0, 2)
      + getFilteredPersonName(normalizedNames).substring(0, 2)
    );
  }

  if (isEmpty(normalizedSecondSurname)) {
    return (
      normalizedFirstSurname.substring(0, 2)
      + getFilteredPersonName(normalizedNames).substring(0, 2)
    );
  }

  if (isFirstLastNameIsTooShort(firstSurname)) {
    return (
      normalizedFirstSurname.charAt(0)
      + normalizedSecondSurname.charAt(0)
      + getFilteredPersonName(normalizedNames).substring(0, 2)
    );
  }
  return (
    normalizedFirstSurname.charAt(0)
    + firstVowelExcludingFirstCharacterOf(normalizedFirstSurname)
    + normalizedSecondSurname.charAt(0)
    + getFilteredPersonName(normalizedNames).charAt(0)
  );
}

export function generateRFC({
  names, firstSurname, secondSurname, dob,
}) {
  const nameCode = getNameCode(names, firstSurname, secondSurname);
  const obfuscatedNameCode = obfuscateForbiddenWords(nameCode);
  const birthdayCode = getBirthdayCode(dob);

  const PII = obfuscatedNameCode + birthdayCode;
  const homoclave = calculateHomoclave(names, firstSurname, secondSurname);
  const digit = calculateDigit(PII + homoclave);

  return PII + homoclave + digit;
}
