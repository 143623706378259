import styled from 'styled-components';

export const LoadingScreenContainer = styled.div`
  height:100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8rem 1.5rem 5rem;
  justify-content: flex-end;

  h1 {
    max-width: 13ch;
  }
`;

LoadingScreenContainer.displayName = 'LoadingScreenContainer';
