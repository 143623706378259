import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';

function Disclaimer() {
  return (
    <Box tag="p" margin="auto 0" marginBottom="8x" marginTop="40px">
      <Typography
        variant="paragraph2"
        intlKey="PROSPECT_REGISTRATION_FORM.ADDRESS_STEP.PRIVACY_POLICY.LABEL_2"
        tag="span"
        intlValues={{
          a: (
            <Link
              href="https://nu.com.mx/terminos-y-condiciones"
              target="_blank"
              rel="noopener"
              typographyProps={{ variant: 'paragraph2', strong: true }}
            >
              aceptas los Términos y Condiciones
            </Link>
          ),
        }}
      />
    </Box>
  );
}

export default Disclaimer;
