import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';

export const getGeoLocalitiesList = async (state, city, urls) => {
  if (!state || !city) {
    throw new Error('user selected state and city is required');
  }

  const discoveryURLs = urls || await fetchDiscoveryURLs();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.geo_state) {
    throw new Error('Discovery fetch geo_locality response unexpected - missing endpoint');
  }

  const result = await fetchJson(discoveryURLs.geo_locality, {
    method: 'POST',
    body: {
      country: 'MX', // only works for mx
      state,
      city,
    },
  });

  return result;
};
