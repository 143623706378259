import { createNameCode } from './createNameCode';
import { createBirthdayCode } from './createBirthdayCode';
import { createConsonantCode } from './createConsonantCode';
import { createVerificationDigit } from './verificationDigit';
import { getHomonimia } from './homonimia';

export function generateCURP({
  names,
  firstSurname,
  secondSurname,
  dob,
  gender,
  entity,
}) {
  // 1-4
  const nameCode1To4Digits = createNameCode(names, firstSurname, secondSurname);

  // 5-10
  const dobCode5to10Digits = createBirthdayCode(dob);

  // 11
  gender.toUpperCase(); // H

  // 12-13
  entity.toUpperCase(); // CH

  // 14-16
  const consonantCode14to16Digits = createConsonantCode(names, firstSurname, secondSurname);

  // 17
  const homonimia = getHomonimia(dob);

  const curp = nameCode1To4Digits
  + dobCode5to10Digits
  + gender
  + entity
  + consonantCode14to16Digits
  + homonimia;

  // 18
  const verificationDigit = createVerificationDigit(curp);

  return curp + verificationDigit;
}
