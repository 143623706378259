/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import { useCombobox } from 'downshift';
import Image from '@nubank/nuds-web/components/Image/Image';
import PropTypes from 'prop-types';

import StyledBox from './StyledBox';
import ShowIcon from './ShowIcon';

const StyledUl = styled.ul`
  width: 100%;
  background-color: #FFF;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 9999;
  border-left: 1px #EFEFEF solid;
  border-right: 1px #EFEFEF solid;
  border-bottom: 1px #EFEFEF solid;

  li {
    list-style: none;
    padding: 14px;
    cursor: pointer;
    font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.16px;
    display: flex;
    gap: 8px;
    align-items: center;

    &:hover {
      background-color: #F5F5F5;
    }
  }
`;

function getOptionsFilter(inputValue) {
  const lowerCasedInputValue = inputValue.toLowerCase();

  return function optionsFilter(option) {
    return (
      !inputValue || option.label.toLowerCase().includes(lowerCasedInputValue)
    );
  };
}

function CustomSelectField({
  name,
  id,
  isDisabled,
  onChange,
  options,
  placeholder,
  externalValue,
  isLoading,
}) {
  const [items, setItems] = React.useState(options);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [showError, setShowError] = React.useState(false);

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    openMenu,
  } = useCombobox({
    onInputValueChange({ inputValue }) {
      if (inputValue === '') {
        setSelectedItem(null);
        onChange(name, '');
      }

      const results = options.filter(getOptionsFilter(inputValue));

      setItems(results);

      if (results.length === 0) {
        // show error if there are no results
        setShowError(true);
      }
    },
    items,
    id,
    selectedItem,
    itemToString(item) {
      return item ? item.label : '';
    },
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      setSelectedItem(newSelectedItem);
      onChange(name, newSelectedItem.value);
    },
  });

  useEffect(() => {
    if (externalValue) {
      const newSelectedItem = items.find(item => item.value === externalValue);
      setSelectedItem(newSelectedItem);
    } else {
      setSelectedItem(null);
    }
  }, [externalValue]);

  useEffect(() => {
    setItems(options);
  }, [options]);

  return (
    <div>
      <StyledBox onClick={() => {
        if (isDisabled) return;

        openMenu(!isOpen);
        setItems(options);
      }}
      >
        <input
          id={name}
          name={name}
          placeholder=" "
          disabled={isDisabled}
          {...getInputProps()}
        />

        <label htmlFor={name} {...getLabelProps()}>
          {placeholder}
        </label>

        <ShowIcon
          isDisabled={isDisabled}
          isOpen={isOpen}
          isError={showError && !selectedItem}
          isLoading={isLoading}
        />

      </StyledBox>

      <StyledUl
        {...getMenuProps()}
      >
        {isOpen
        && items.map((item, index) => (
          <li
            key={item.value}
            {...getItemProps({ item, index })}
          >
            {
              selectedItem?.value === item.value
                ? (
                  <Image
                    src="one-step/check.svg"
                    webp={false}
                    alt="Icono"
                    width="20px"
                    height="20px"
                  />
                )
                : <Box width="24px" height="24px" />
            }
            {item.label}
          </li>
        ))}
      </StyledUl>
    </div>
  );
}

CustomSelectField.defaultProps = {
  isDisabled: false,
  isLoading: false,
  onChange: () => {},
};

CustomSelectField.propTypes = {
  externalValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default CustomSelectField;
