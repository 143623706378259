const useMobileOS = () => {
  if (typeof window === 'undefined') {
    return 'other';
  }

  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  return 'other';
};

export default useMobileOS;
