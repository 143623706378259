import React from 'react';
import Icon from '@nubank/nuds-web/components/Icon/Icon';
import PropTypes from 'prop-types';

function ToggleButton({ isOpen }) {
  return (
    isOpen
      ? <Icon name="chevron-up" title="Abrir menu" />
      : <Icon name="chevron-down" title="Cerrar menu" />
  );
}

ToggleButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default ToggleButton;
