const realStates = {
  AS: 'AG',
  BC: 'BC',
  BS: 'BS',
  CC: 'CM',
  CS: 'CS',
  CH: 'CH',
  CL: 'CO',
  CM: 'CL',
  DF: 'DF',
  DG: 'DG',
  GT: 'GT',
  GR: 'GR',
  HG: 'HG',
  JC: 'JA',
  MC: 'EM',
  MN: 'MI',
  MS: 'MO',
  NT: 'NA',
  NL: 'NL',
  OC: 'OA',
  PL: 'PU',
  QT: 'QT',
  QR: 'QR',
  SP: 'SL',
  SL: 'SI',
  SR: 'SO',
  TC: 'TB',
  TS: 'TM',
  TL: 'TL',
  VZ: 'VE',
  YN: 'YU',
  ZS: 'ZA',
  NE: 'NE',
};

export const mapCurpStates = value => realStates[value];
