import styled from 'styled-components';
import Form from '@nubank/nuds-web/components/Form/Form';

export const StyledForm = styled(Form)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  form {
    height: 100%;
  }
`;

StyledForm.displayName = 'StyledForm';
