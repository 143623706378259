import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';

export const sendCandidateDocuments = async (citizenId, taxId, urls) => {
  if (!citizenId || !taxId) {
    throw new Error('Citizen id and taxId expected');
  }

  const discoveryURLs = urls || await fetchDiscoveryURLs();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.candidate_documents) {
    throw new Error('Discovery fetch response unexpected - missing endpoint');
  }

  const payload = {
    'citizen-id': citizenId, // CURP
    'tax-id': taxId, // RFC
  };

  const result = await fetchJson(discoveryURLs.candidate_documents, {
    method: 'POST',
    body: payload,
  });

  return result;
};

