export const mapFormAdditionalParametersToWire = (formValues, gaClientId, authRequestId) => {
  const wireAdditionalParameters = {};

  if (formValues.whatsappAccepted !== undefined) {
    wireAdditionalParameters.opt_in = formValues.whatsappAccepted;
  }

  if (gaClientId !== undefined) {
    wireAdditionalParameters.ga_client_id = gaClientId.replace(/GA[0-9]+.[0-9]+./, '');
  }

  if (authRequestId !== undefined) {
    wireAdditionalParameters.authorization_request_id = authRequestId;
  }

  return wireAdditionalParameters;
};
