import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

export const FeedbackBox = styled(Box)`
  margin-top: ${props => (props.adjustMargin === true ? '-22px' : '-34px')};
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Counter = styled(Typography)`
  color: ${props => (props.input === props.length ? '#111111B3' : '#D72923')};
  font-size: 14px;
  font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
  text-align: right;
`;

export const ErrorMessage = styled(Typography)`
  color: ${props => (props.green === true ? '#1F7F45' : '#ff8e16')};
  font-size: 14px;
  font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
  text-align: left;
  max-width: 90%;
`;

export const StyledTextFieldBox = styled(Box)`
  div > label > input {
    border-color: ${props => (props.activeFeedback === true ? props.color : 'inherit')};
    color: #111;
    text-transform: uppercase;
  }

  div > label > span > svg {
    display: none;
  }
`;

export const LoaderBox = styled(Box)`
  float: right;
  position: relative;
  top: 33px;
`;
