import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SelectField from '@nubank/nuds-web/components/SelectField/SelectField';

import states from '../../../../../utils/form/curpStates';
import countries from '../../../../../utils/form/curpCountries';

const CountryPicker = ({ setFieldValue }) => {
  const { formatMessage } = useIntl();

  const [isEntityDisabled, setIsEntityDisabled] = useState(false);

  const handleCountryChange = countryCode => {
    if (countryCode === 'MEX') {
      setIsEntityDisabled(false);
      setFieldValue('entity', '');
    } else {
      setIsEntityDisabled(true);
      setFieldValue('entity', 'NE');
    }
  };

  return (
    <>
      <SelectField
        id="countryOfBirth"
        name="countryOfBirth"
        label={formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP2.CURP_XP.COUNTRY_OF_BIRTH.LABEL' })}
        options={countries}
        autocomplete="off"
        syncValidations={{
          required: formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP2.CURP_XP.COUNTRY_OF_BIRTH.VALIDATION_MESSAGE' }),
        }}
        onChange={handleCountryChange}
      />

      <SelectField
        id="entity"
        name="entity"
        label={formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP2.CURP_XP.ENTITY_OF_BIRTH.LABEL' })}
        options={states}
        autocomplete="off"
        syncValidations={{
          required: formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP2.CURP_XP.ENTITY_OF_BIRTH.VALIDATION_MESSAGE' }),
        }}
        disabled={isEntityDisabled}
      />
    </>
  );
};

CountryPicker.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
};

export default CountryPicker;
