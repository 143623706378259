import React, { useEffect } from 'react';
import styled from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import Link from '@nubank/nuds-web/components/Link/Link';

import useMobileOS from '../../../../../../utils/hooks/useMobileOS';
import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { approvedScreenRegisterViewEvent, securedCardRTREvent } from '../../../../tracking';
import { FormStepContainer } from '../../../../styles/FormStepContainer';
import { StyledHeader } from '../../styles/Header';
import { StyledButton } from '../../styles/StyledButton';
import { StyledTitle } from '../../styles/StyledTitle';

const Line = styled(Box)`
  border: 1px solid rgba(17 17 17 / 10%);
  margin-top: 5px;
  width: 97%;
`;

function SecuredCardXp() {
  const { closeRegistrationForm } = useSiteContext();
  const mobileSystem = useMobileOS();

  useEffect(() => {
    approvedScreenRegisterViewEvent();
    securedCardRTREvent();
  }, []);

  const getOSLink = () => {
    if (mobileSystem === 'ios') {
      window.open('https://link.nubank.com.br/5YMt1FwBMBb', '_blank');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.nu.production&listing=ric', '_blank');
    }
  };

  return (
    <FormStepContainer height="100%">
      <StyledHeader>
        <Button
          variant="basic"
          onClick={() => closeRegistrationForm(true)}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </StyledHeader>

      {/* TITLE AND SUBTITLE */}

      <StyledTitle
        variant="heading3"
        marginBottom="4x"
      >
        👉 Descarga la app Nu para el siguiente paso
      </StyledTitle>

      <Typography
        variant="subtitle1"
        marginBottom="4x"
      >
        Todo está listo para que
        <Typography tag="span" strong variant="subtitle1" color="primary">
          {' '}
          continúes en la app con el mismo correo y el número celular
          {' '}
        </Typography>
        que nos diste en tu registro.
      </Typography>

      {/* DOWNLOAD BTN */}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="2x"
      >
        <StyledButton
          iconProps={{ name: 'arrow-right' }}
          extended
          type="button"
          onClick={() => getOSLink()}
        >
          Descargar App Nu
        </StyledButton>
      </Box>

      {/* CARDS ROWS */}

      <Box
        marginBottom={{
          xs: '1x',
          md: '1x',
          lg: '3.25rem',
        }}
        paddingHorizontal={{
          xs: '0',
        }}
      >

        <Box
          marginBottom={{
            xs: '1x',
            md: '1x',
            lg: '3.25rem',
          }}
          paddingHorizontal={{
            xs: '0',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            marginBottom="6x"
          >
            {/* CREDIT CARD + CUENTA ROW */}
            <Box
              paddingVertical="4x"
              display="flex"
              paddingHorizontal="2x"
              justifyContent="flex-start"
              borderRadius="12px"
              background="#F3EDFF"
              marginBottom="2x"
            >
              <Image
                alt="Icono de una tarjeta"
                src="one-step/rtr/secured-pre-ric.svg"
                webp={false}
                width="120px"
              />
              <Box
                paddingRight="4x"
                width="100%"
              >
                <Typography
                  variant="paragraph1"
                  marginLeft="2x"
                  marginBottom="2x"
                  strong
                >
                  Tarjeta Garantizada y Cuenta Nu
                  <Typography
                    tag="span"
                    variant="paragraph1"
                    marginLeft="1x"
                  >
                    para que tu historial crediticio tenga una nueva oportunidad
                  </Typography>
                </Typography>
              </Box>
            </Box>

            {/* BENEFITS */}
            <Box marginTop="3x">
              <Typography variant="heading4" strong>
                Qué beneficios te da Nu:
              </Typography>

              <Line tag="hr" />

              <Box
                marginTop="4x"
                display="flex"
                flexDirection="row"
              >
                <Image
                  alt="Icono de carita feliz"
                  src="one-step/rtr/smiley.svg"
                  webp={false}
                  width="24px"
                />
                <Typography
                  variant="paragraph1"
                  marginLeft="4x"
                  marginTop="-2px"
                >
                  Atención
                  <Typography tag="span" strong variant="paragraph1">
                    {' '}
                    humana y soporte las 24/7.
                  </Typography>
                </Typography>
              </Box>
              <Box
                marginTop="4x"
                display="flex"
                flexDirection="row"
              >
                <Image
                  alt="Icono de tarjetas"
                  src="one-step/rtr/cards.svg"
                  webp={false}
                  width="40px"
                />
                <Typography
                  variant="paragraph1"
                  marginLeft="4x"
                  marginTop="-4px"
                >
                  El buen uso de tu tarjeta te ayuda a tener
                  <Typography tag="span" strong variant="paragraph1">
                    {' '}
                    mejores reportes a buró de crédito.
                    {' '}
                  </Typography>
                </Typography>
              </Box>
              <Box
                marginTop="4x"
                display="flex"
                flexDirection="row"
              >
                <Image
                  alt="Icono de corazón"
                  src="one-step/rtr/heart.svg"
                  webp={false}
                  width="24px"
                />
                <Typography
                  variant="paragraph1"
                  marginLeft="4x"
                  marginTop="-5px"
                >
                  <Typography tag="span" strong variant="paragraph1">
                    $ 0 anualidad
                  </Typography>
                  {' '}
                  ni comisiones por uso mínimo.
                </Typography>
              </Box>

            </Box>

            {/* CAT LINK */}

            <Box
              display="flex"
              justifyContent="center"
              marginTop="12x"
            >
              <Link
                href="/cat"
                variant="action"
                typographyProps={{ variant: 'paragraph1' }}
                target="_blank"
              >
                CAT de Tarjeta Garantizada Nu
              </Link>
            </Box>

          </Box>
        </Box>

      </Box>

    </FormStepContainer>
  );
}

export default SecuredCardXp;
