const digitMap = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  A: 10,
  B: 11,
  C: 12,
  D: 13,
  E: 14,
  F: 15,
  G: 16,
  H: 17,
  I: 18,
  J: 19,
  K: 20,
  L: 21,
  M: 22,
  N: 23,
  '&': 24,
  O: 25,
  P: 26,
  Q: 27,
  R: 28,
  S: 29,
  T: 30,
  U: 31,
  V: 32,
  W: 33,
  X: 34,
  Y: 35,
  Z: 36,
  ' ': 37,
  Ñ: 38,
};

const GOVERNMENT_FACTOR_VERIFICATION_ID = 11;
const GOVERNMENT_FACTOR_MULTIPLIER = 13;

function descendingSumFromFactor(rfc) {
  return rfc.reduce(
    (total, current, index) => total + current * (GOVERNMENT_FACTOR_MULTIPLIER - index),
    0,
  );
}

function mapRFCCharToDigitMap(rfc) {
  return rfc.map(char => digitMap[char.toUpperCase()] || 0);
}

export default function calculateDigit(rfc) {
  const rfcArray = rfc.split('');
  const mappedRfcToTable = mapRFCCharToDigitMap(rfcArray);
  const sum = descendingSumFromFactor(mappedRfcToTable);

  const reminder = sum % GOVERNMENT_FACTOR_VERIFICATION_ID;

  if (reminder === 0) return '0';

  const hexadecimalResult = (GOVERNMENT_FACTOR_VERIFICATION_ID - reminder).toString(16);
  return hexadecimalResult.toUpperCase();
}
