import React from 'react';
import styled from 'styled-components';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import PropTypes from 'prop-types';

const StyledDrawer = styled(Drawer)`
  #resend-otp-drawer {
    bottom: 0;
    top: auto;
    height: 400px;
    border-radius: 16px 16px 0 0;
    padding: 24px;
  }
`;

const StyledTitle = styled(Typography)`
  font-size: 26px;
`;

const StyledTypography = styled(Typography)`
  font-size: 20px;

  span {
    font-weight: bold;
  }
`;

const StyledCaption = styled(Typography)`
  color: #5c5c5c;
  font-size: 18px;
  
  span {
    color: #820bd1;
    font-weight: bold;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  svg {
    margin-top: 8px;
    margin-left: 12px;
  }
`;

function ResendDrawer({
  setShowResendModal, showResendModal, handleResendNIP,
  resendTimerCount, isResendTimerRunning,
}) {
  const handleSubmit = async () => {
    handleResendNIP();
  };

  return (
    <StyledDrawer
      width="100%"
      id="resend-otp-drawer"
      direction="bottom"
      desktopDirection="bottom"
      open={showResendModal}
      onClose={() => setShowResendModal(false)}
    >
      {({ DrawerContent, DrawerCloseButton }) => (
        <DrawerContent>
          <DrawerCloseButton onClick={() => setShowResendModal(false)} />

          <Box paddingHorizontal="4x">

            <Box minHeight="240px">
              <StyledTitle
                marginBottom="6x"
                marginTop="16px"
                variant="heading4"
              >
                Tu código va en camino 🚀
              </StyledTitle>

              <StyledTypography
                marginBottom="8x"
                variant="subtitle1"
              >
                <span>
                  Lo enviamos por WhatsApp y SMS,
                </span>
                {' '}
                te sugerimos revisar que tu celular tenga buena señal y acceso a internet.
              </StyledTypography>

              {
              isResendTimerRunning && (

              <StyledCaption
                marginBottom="12x"
                variant="subtitle2"
              >
                <span>
                  Revisa tus mensajes,
                </span>
                {' '}
                podrás solicitar un código nuevo en
                {' '}
                {resendTimerCount}
                {' '}
                segundos...
              </StyledCaption>
              )
            }
            </Box>

            <StyledButton
              id="new-resend-otp-submit-btn"
              type="button"
              variant="contained"
              styleVariant="primary"
              disabled={isResendTimerRunning}
              extended
              onClick={handleSubmit}
            >
              Enviar código nuevo
            </StyledButton>

          </Box>

        </DrawerContent>
      )}
    </StyledDrawer>
  );
}

ResendDrawer.propTypes = {
  handleResendNIP: PropTypes.func.isRequired,
  isResendTimerRunning: PropTypes.bool.isRequired,
  resendTimerCount: PropTypes.number.isRequired,
  setShowResendModal: PropTypes.func.isRequired,
  showResendModal: PropTypes.bool.isRequired,
};

export default ResendDrawer;
