const states = [
  { label: 'Aguascalientes', value: 'AG' },
  { label: 'Baja California', value: 'BC' },
  { label: 'Baja California Sur', value: 'BS' },
  { label: 'Campeche', value: 'CM' },
  { label: 'Chiapas', value: 'CS' },
  { label: 'Chihuahua', value: 'CH' },
  { label: 'Coahuila de Zaragoza', value: 'CO' },
  { label: 'Colima', value: 'CL' },
  { label: 'Ciudad de México', value: 'DF' },
  { label: 'Durango', value: 'DG' },
  { label: 'Guanajuato', value: 'GT' },
  { label: 'Guerrero', value: 'GR' },
  { label: 'Hidalgo', value: 'HG' },
  { label: 'Jalisco', value: 'JA' },
  { label: 'México', value: 'EM' },
  { label: 'Michoacán de Ocampo', value: 'MI' },
  { label: 'Morelos', value: 'MO' },
  { label: 'Nayarit', value: 'NA' },
  { label: 'Nuevo León', value: 'NL' },
  { label: 'Oaxaca', value: 'OA' },
  { label: 'Puebla', value: 'PU' },
  { label: 'Querétaro', value: 'QT' },
  { label: 'Quintana Roo', value: 'QR' },
  { label: 'San Luis Potosí', value: 'SL' },
  { label: 'Sinaloa', value: 'SI' },
  { label: 'Sonora', value: 'SO' },
  { label: 'Tabasco', value: 'TB' },
  { label: 'Tamaulipas', value: 'TM' },
  { label: 'Tlaxcala', value: 'TL' },
  { label: 'Veracruz de Ignacio de la Llave', value: 'VE' },
  { label: 'Yucatán', value: 'YU' },
  { label: 'Zacatecas', value: 'ZA' },
];

export default states;
