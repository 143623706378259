import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import isEmpty from '@nubank/nuds-web/utils/string/isEmpty';

import { generateRFC } from '../../../../../utils/rfc/rfcGenerator';
import { formatDOB } from '../../../../../utils/form/formatDOB';
import {
  validateFieldsForCURP, validateFieldsforRFC, isValidPersonRFC,
} from '../../../../../utils/form/validationUtils';
import { generateCURP } from '../../../../../utils/curp';
import { useRegistrationFormContext } from '../../RegistrationForm/RegistrationForm';
import Spinner from '../../../../../components/Spinner/Spinner';

import {
  Counter, ErrorMessage, FeedbackBox, StyledTextFieldBox, LoaderBox,
} from './StyleFeedback';

const CurpFeedbackExperiment = ({
  currentStepValues, setFieldValue, invalidCURP,
  isLoading, invalidTaxId, recalculatedTaxId,
}) => {
  const { formatMessage } = useIntl();
  const endRef = useRef(null);
  const { setUncheckPersonalInfoBox } = useRegistrationFormContext();
  const [curpInput, setCurpInput] = useState('');
  const [taxIdInput, setTaxIdInput] = useState('');
  const [invalidMessageCURP, setInvalidMessageCURP] = useState(false);
  const [rfcWasRecalculated, setRfcWasRecalculated] = useState(false);
  const [currentTaxId, setCurrentTaxId] = useState('');

  const {
    names, firstSurname, secondSurname, day, month, year, gender, entity, countryOfBirth,
  } = currentStepValues;

  // Generate CURP and RFC and display them in the inputs
  useEffect(() => {
    if (isEmpty(names) || isEmpty(firstSurname)
    || isEmpty(day) || isEmpty(month) || isEmpty(year)
     || isEmpty(gender) || isEmpty(entity)) {
      return;
    }

    const formattedDOB = formatDOB(day, month, year);

    const scrollToBottom = () => {
      endRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    if (validateFieldsForCURP({
      names, firstSurname, dob: formattedDOB, gender, entity,
    })) {
      setFieldValue('curp', generateCURP({
        names, firstSurname, secondSurname, dob: formattedDOB, gender, entity,
      }));
      setCurpInput(generateCURP({
        names, firstSurname, secondSurname, dob: formattedDOB, gender, entity,
      }));
    }

    if (validateFieldsforRFC({ names, firstSurname, dob: formattedDOB })) {
      const taxId = generateRFC({
        names, firstSurname, secondSurname, dob: formattedDOB,
      });
      setFieldValue('taxId', taxId);
      setTaxIdInput(taxId);
      setCurrentTaxId(taxId);
    }

    scrollToBottom();
  }, [names, firstSurname, secondSurname, day, month, year, gender, entity, countryOfBirth]);

  // Display invalid curp message
  useEffect(() => {
    if (invalidCURP) { setInvalidMessageCURP(true); }
  }, [invalidCURP]);

  // User updates the RFC
  useEffect(() => {
    if (!invalidCURP) {
      if (rfcWasRecalculated && taxIdInput !== rfcWasRecalculated) {
        // Hide the message
        setRfcWasRecalculated(false);
      }
    }
  }, [taxIdInput]);

  // Uncheck the checkbox when the user updates the CURP or RFC
  useEffect(() => {
    setUncheckPersonalInfoBox(true);
  }, [curpInput, taxIdInput]);

  useEffect(() => {
    if (invalidTaxId) {
      setCurrentTaxId(recalculatedTaxId);
      setFieldValue('taxId', recalculatedTaxId);
      setRfcWasRecalculated(true);
    }
  }, [invalidTaxId]);

  return (
    <>
      <StyledTextFieldBox activeFeedback={invalidMessageCURP}>
        { isLoading
          && (
          <LoaderBox
            position="relative"
            float="right"
            top="33px"
          >
            <Spinner color="#820AD1" />
          </LoaderBox>
          )}
        <TextField
          id="curp"
          name="curp"
          autoComplete="off"
          maxLength={18}
          label={formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP2.CURP_XP.CURP.LABEL' })}
          syncValidations={{
            required: 'Detectamos un error, edita este campo.',
            curp: 'Confirma tu CURP antes de continuar',
          }}
          onInput={e => setCurpInput(e.target.value)}
        />
      </StyledTextFieldBox>
      <FeedbackBox adjustMargin={isLoading}>
        <ErrorMessage>
          {invalidMessageCURP && isValidPersonRFC(taxIdInput) ? 'Te sugerimos revisar tu CURP' : null}
        </ErrorMessage>
        <Counter input={curpInput.length} length={18}>
          {`${18 - curpInput.length}/18`}
        </Counter>
      </FeedbackBox>

      <StyledTextFieldBox
        activeFeedback={rfcWasRecalculated}
        color={rfcWasRecalculated ? '#1F7F45' : '#ff8e16'}
      >
        { isLoading
          && (
          <LoaderBox
            position="relative"
            float="right"
            top="33px"
          >
            <Spinner />
          </LoaderBox>
          )}

        <TextField
          id="taxId"
          name="taxId"
          autoComplete="off"
          maxLength={13}
          label={formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP2.CURP_XP.RFC.LABEL' })}
          syncValidations={{
            required: formatMessage({ id: 'PROSPECT_REGISTRATION_FORM.PERSONAL_INFO_STEP2.CURP_XP.RFC.VALIDATION_MESSAGE' }),
            taxId: 'Revisa que tu RFC coincida con tu CURP',
          }}
          ref={endRef}
          onInput={e => setTaxIdInput(e.target.value)}
        />
      </StyledTextFieldBox>
      <FeedbackBox adjustMargin={isLoading}>
        <ErrorMessage green={rfcWasRecalculated}>
          {rfcWasRecalculated && isValidPersonRFC(currentTaxId) ? 'Actualizamos tu RFC con tu CURP' : null}
        </ErrorMessage>
        <Counter input={taxIdInput.length} length={13}>
          {`${13 - taxIdInput.length}/13`}
        </Counter>
      </FeedbackBox>
    </>
  );
};

CurpFeedbackExperiment.propTypes = {
  currentStepValues: PropTypes.shape({
    countryOfBirth: PropTypes.string,
    day: PropTypes.string,
    entity: PropTypes.string,
    firstSurname: PropTypes.string,
    gender: PropTypes.string,
    month: PropTypes.string,
    names: PropTypes.string,
    secondSurname: PropTypes.string,
    year: PropTypes.string,
  }).isRequired,
  invalidCURP: PropTypes.bool.isRequired,
  invalidTaxId: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  recalculatedTaxId: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CurpFeedbackExperiment;
