import isEmpty from '@nubank/nuds-web/utils/string/isEmpty';

import { getFirstConsonant, removeSpecialChars } from './helpers';
import { normalizeNamesAndLastnames } from './normalize';

export function createConsonantCode(names, firstSurname, secondSurname) {
  const {
    normalizedNames,
    normalizedFirstSurname,
    normalizedSecondSurname,
  } = normalizeNamesAndLastnames(names, firstSurname, secondSurname);

  const firstChar = getFirstConsonant(normalizedFirstSurname);
  let secondChar = '';

  if (!normalizedSecondSurname || isEmpty(normalizedSecondSurname)) {
    secondChar = 'X';
  } else {
    secondChar = getFirstConsonant(normalizedSecondSurname);
  }

  const thirdChar = getFirstConsonant(normalizedNames);

  return removeSpecialChars(firstChar + secondChar + thirdChar);
}

