import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';

export const getGeoStatesList = async urls => {
  const discoveryURLs = urls || await fetchDiscoveryURLs();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.geo_state) {
    throw new Error('Discovery fetch geo_state response unexpected - missing endpoint');
  }

  const result = await fetchJson(discoveryURLs.geo_state, {
    method: 'POST',
    body: {
      country: 'MX', // only works for mx
    },
  });

  return result;
};
