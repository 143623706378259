import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import SelectField from '@nubank/nuds-web/components/SelectField/SelectField';

const generateDayOptions = days => {
  const dayOptions = Array.from(
    { length: days },
    (_, i) => ({ label: `${i + 1}`, value: `${i + 1}` }),
  );

  return dayOptions;
};

const getDaysInMonth = (year, month) => {
  const days = new Date(year, month, 0).getDate();
  const dayOptions = generateDayOptions(days);

  return dayOptions;
};

const MIN_LEGAL_AGE_REQUIRED = 18;

const MONTH_OPTIONS = [
  { label: 'Enero', value: '1' },
  { label: 'Febrero', value: '2' },
  { label: 'Marzo', value: '3' },
  { label: 'Abril', value: '4' },
  { label: 'Mayo', value: '5' },
  { label: 'Junio', value: '6' },
  { label: 'Julio', value: '7' },
  { label: 'Agosto', value: '8' },
  { label: 'Septiembre', value: '9' },
  { label: 'Octubre', value: '10' },
  { label: 'Noviembre', value: '11' },
  { label: 'Diciembre', value: '12' },
];

const getAvailableMonths = yearString => {
  const year = parseInt(yearString, 10);
  const currentDate = new Date();
  const maxBirthYear = currentDate.getFullYear() - MIN_LEGAL_AGE_REQUIRED;

  const availableMonths = [];

  for (let month = 0; month < 12; month += 1) {
    let birthYear = year;
    if (month > currentDate.getMonth()) {
      birthYear += 1;
    }

    if (birthYear <= maxBirthYear) {
      const label = new Date(year, month).toLocaleString('es-MX', { month: 'long' });
      availableMonths.push({
        label: label.charAt(0).toUpperCase() + label.slice(1),
        value: (month + 1).toString(),
      });
    }
  }

  return availableMonths;
};

const generateYearOptions = () => {
  const yearOptions = [];
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 100;
  const endYear = currentYear - MIN_LEGAL_AGE_REQUIRED;

  for (let i = endYear; i >= startYear; i -= 1) {
    yearOptions.push({ label: i.toString(), value: i.toString() });
  }

  return yearOptions;
};

const DatePicker = ({ selectedMonth, selectedYear, setFieldValue }) => {
  const [days, setDays] = useState(generateDayOptions(31));
  const [monthOptions, setMonthOptions] = useState(MONTH_OPTIONS);
  const years = useMemo(() => generateYearOptions(), []);

  useEffect(() => {
    const generatedDaysArray = selectedMonth
      ? getDaysInMonth(selectedYear, selectedMonth)
      : generateDayOptions(31);

    setDays(generatedDaysArray);
  }, [selectedMonth, selectedYear]);

  const generateNewMonths = yearString => {
    const year = parseInt(yearString, 10);

    if (year === new Date().getFullYear() - MIN_LEGAL_AGE_REQUIRED) {
      setMonthOptions(getAvailableMonths(year)); // Update state with available months
      setFieldValue('month', '');
    } else {
      setMonthOptions(MONTH_OPTIONS); // Reset to default options
    }
  };

  const validateYearAfterBlur = year => {
    // Invalid year captured, force the user error
    if (!years.some(yearOption => yearOption.value === year)) {
      setFieldValue('year', '');
    }
  };

  return (
    <>
      <SelectField
        id="day"
        name="day"
        label="Día"
        syncValidations={{
          required: 'Por favor, llena este dato',
          day: 'Por favor, llena este dato',
        }}
        noOptionsLabel="Verifica el número del día"
        options={days}
        autocomplete="off"
      />

      <SelectField
        id="month"
        name="month"
        key={selectedYear}
        label="Mes"
        syncValidations={{
          required: 'Por favor, llena este dato',
          month: 'Por favor, llena este dato',
        }}
        noOptionsLabel="Verifica el mes"
        options={monthOptions}
        autocomplete="off"
      />

      <SelectField
        id="year"
        name="year"
        onChange={e => generateNewMonths(e)}
        onBlur={e => validateYearAfterBlur(e.target.value)}
        label="Año"
        syncValidations={{
          required: 'Por favor, llena este dato',
          year: 'Por favor, llena este dato',
        }}
        noOptionsLabel="Verifica el número del año"
        options={years}
        autocomplete="off"
        maxLength="4"
      />
    </>
  );
};

DatePicker.propTypes = {
  selectedMonth: PropTypes.string.isRequired,
  selectedYear: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default DatePicker;
