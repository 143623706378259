import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';

export const getAddressDataFromPostCode = async (postcode, urls) => {
  if (!postcode) {
    throw new Error('zip is required');
  }

  const discoveryURLs = urls || await fetchDiscoveryURLs();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.geo_region) {
    throw new Error('Discovery fetch response unexpected - missing endpoint');
  }

  const result = await fetchJson(discoveryURLs.geo_region, {
    method: 'POST',
    body: {
      postcode,
    },
  });

  return result;
};
