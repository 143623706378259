import PropTypes from 'prop-types';

export const CREDIT_CARD = 'CREDIT_CARD';
export const MULTI_PRODUCT = 'MULTI_PRODUCT';

export const ProspectTypeTypes = PropTypes.oneOf(['credit_card', 'multi-product']);

export const ProspectTypeShape = PropTypes.shape({
  buttonLabel: PropTypes.string.isRequired,
  discoveryFields: PropTypes.shape({
    inviter: PropTypes.oneOf(['register_prospect', 'register_prospect_global']).isRequired,
    unsolicited: PropTypes.oneOf(['register_prospect', 'register_prospect_global_web']).isRequired,
  }),
  formTitle: PropTypes.string.isRequired,
  type: ProspectTypeTypes.isRequired,
});

function createProspectType(buttonLabel, formTitle, type, unsolicitedField, inviterField) {
  return {
    buttonLabel,
    formTitle,
    type,
    discoveryFields: {
      inviter: inviterField || unsolicitedField,
      unsolicited: unsolicitedField,
    },
  };
}

const prospectTypes = {
  [CREDIT_CARD]: createProspectType(
    'APPLICATION.FORM.SUBMIT',
    'APPLICATION.FORM.TITLE',
    'credit_card',
    'register_prospect',
  ),
  [MULTI_PRODUCT]: createProspectType(
    'APPLICATION.FORM.SUBMIT',
    'APPLICATION.FORM.TITLE',
    'multi-product',
    'register_prospect_global_web',
    'register_prospect_global',
  ),
};

export function getProspectType(prospectTypeKey = '') {
  return prospectTypes[prospectTypeKey];
}
