import removeAccents from '@nubank/nuds-web/utils/string/removeAccents';

export function firstVowelExcludingFirstCharacterOf(surname) {
  const result = /[aeiou]/i.exec(surname.slice(1));
  return result ? result[0] : '';
}

function generateNotNeededWordsRegex() {
  const notNeededWordsInRFC = ['DE', 'LA', 'LAS', 'MC', 'VON', 'DEL', 'LOS', 'Y', 'MAC', 'VAN', 'MI'];
  const ocurrencesFromWordsRegex = notNeededWordsInRFC
    .map(word => `^${word} | ${word} | ${word}$`)
    .join('|');

  return new RegExp(`(?:${ocurrencesFromWordsRegex})`, 'g');
}

export function standardizeString(str) {
  const spaceDetectRegex = /\s+/g;

  return removeAccents(str)
    .toUpperCase()
    .replace(spaceDetectRegex, '  ')
    .replace(generateNotNeededWordsRegex(), '')
    .replace(spaceDetectRegex, ' ')
    .trim();
}

export function getFilteredPersonName(names) {
  if (names.split(' ').length > 1) {
    return names.replace(/^(JOSE|MARIA|MA|MA\.)\s+/i, '');
  }

  return names;
}

export function isFirstLastNameIsTooShort(firstSurname) {
  return standardizeString(firstSurname).length <= 2;
}

export function obfuscateForbiddenWords(str) {
  const match = str.match(
    /(BUE[IY]|CAC[AO]|CAGA|KOGE|KAKA|MAME|KOJO|[KQ]ULO|CAGO|CO[GJ]E|COJO|FETO|JOTO|KA[CG]O)/,
  ) || str.match(/(MAMO|MEAR|M[EI]ON|MOCO|MULA|PED[AO]|PENE|PUT[AO]|RATA|RUIN)/);

  return match ? `${str.substring(0, 3)}X` : str;
}
