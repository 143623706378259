import { createGlobalStyle } from 'styled-components';

import { getConfig } from '@nubank/www-latam-commons/config';

const config = getConfig();

export const get = () => new Promise(((resolve, reject) => {
  // eslint-disable-next-line no-undef
  try {
    const reCaptchaV3 = window.grecaptcha;
    return reCaptchaV3.enterprise.ready(() => reCaptchaV3.enterprise.execute(config.recaptchaSiteKey, { action: 'submit' }).then(resolve));
  } catch (error) {
    return reject(error);
  }
}));

export const RecaptchaGlobalStyle = createGlobalStyle`
  .grecaptcha-badge {
    width: auto !important;
    position: sticky !important;
    margin-top: -150px;
    margin-left: calc(100vw - 70px);
    transition: margin-left 0.3s ease-in-out !important;

    &:hover {
      margin-left: calc(100vw - 256px);
    }

    @media (width >= 768px) {
      margin-top: -60px;
    }
  }
`;
