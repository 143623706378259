import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';
import { cookieStorage } from '@nubank/www-latam-commons/utils/storage';

const getClientId = () => {
  const GA_CLIENT_ID_COOKIE = '_ga';

  const gaClientId = cookieStorage.getString(GA_CLIENT_ID_COOKIE);

  if (gaClientId) {
    return gaClientId.replace(/GA[0-9]+.[0-9]+./, '');
  }

  // Backend needs a string value, so we return 'undefined' as string
  return 'undefined';
};

export const registerCandidateData = async (email, urls) => {
  if (!email) {
    throw new Error('Email is required to register candidate');
  }

  const discoveryURLs = urls || await fetchDiscoveryURLs();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.register_candidate) {
    throw new Error('Discovery fetch response unexpected - missing endpoint');
  }

  const payload = {
    email,
    origin: 'global-web',
    device_id: getClientId(),
  };

  const result = await fetchJson(discoveryURLs.register_candidate, {
    method: 'POST',
    body: payload,
  });

  return result;
};

