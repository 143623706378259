const countries = [
  { value: 'AFG', label: 'Afghanistan' },
  { value: 'ALB', label: 'Albania' },
  { value: 'DEU', label: 'Alemania' },
  { value: 'DZA', label: 'Algeria' },
  { value: 'AND', label: 'Andorra' },
  { value: 'AGO', label: 'Angola' },
  { value: 'AIA', label: 'Anguilla' },
  { value: 'ATA', label: 'Antartida' },
  { value: 'ATG', label: 'Antigua y Barbuda' },
  { value: 'SAU', label: 'Arabia Saudita' },
  { value: 'ARG', label: 'Argentina' },
  { value: 'ARM', label: 'Armenia' },
  { value: 'ABW', label: 'Aruba' },
  { value: 'AUS', label: 'Australia' },
  { value: 'AUT', label: 'Austria' },
  { value: 'AZE', label: 'Azerbaijan' },
  { value: 'BHS', label: 'Bahamas' },
  { value: 'BHR', label: 'Bahrein' },
  { value: 'BGD', label: 'Bangladesh' },
  { value: 'BRB', label: 'Barbados' },
  { value: 'BLR', label: 'Belarus' },
  { value: 'BEL', label: 'Belgica' },
  { value: 'BLZ', label: 'Belice' },
  { value: 'BEN', label: 'Benin' },
  { value: 'BMU', label: 'Bermudas' },
  { value: 'BTN', label: 'Bhutan' },
  { value: 'BOL', label: 'Bolivia' },
  { value: 'BES', label: 'Bonaire, Sint Eustatius y Saba' },
  { value: 'BIH', label: 'Bosnia y Herzegovina' },
  { value: 'BWA', label: 'Botswana' },
  { value: 'BRA', label: 'Brasil' },
  { value: 'BRN', label: 'Brunei Darussalam' },
  { value: 'BGR', label: 'Bulgaria' },
  { value: 'BFA', label: 'Burkina Faso' },
  { value: 'BDI', label: 'Burundi' },
  { value: 'CPV', label: 'Cabo Verde' },
  { value: 'KHM', label: 'Camboya' },
  { value: 'CMR', label: 'Camerún' },
  { value: 'CAN', label: 'Canada' },
  { value: 'TCD', label: 'Chad' },
  { value: 'CHL', label: 'Chile' },
  { value: 'CHN', label: 'China' },
  { value: 'COL', label: 'Colombia' },
  { value: 'COM', label: 'Comoras' },
  { value: 'COD', label: 'Congo (Republica Democratica)' },
  { value: 'COG', label: 'Congo' },
  { value: 'CRI', label: 'Costa Rica' },
  { value: 'HRV', label: 'Croacia' },
  { value: 'CUB', label: 'Cuba' },
  { value: 'CUW', label: 'Curaçao' },
  { value: 'CYP', label: 'Chipre' },
  { value: 'CIV', label: 'Costa de Marfil' },
  { value: 'DNK', label: 'Dinamarca' },
  { value: 'DJI', label: 'Djibouti' },
  { value: 'DMA', label: 'Dominica' },
  { value: 'ECU', label: 'Ecuador' },
  { value: 'EGY', label: 'Egipto' },
  { value: 'SLV', label: 'El Salvador' },
  { value: 'ARE', label: 'Emiratos Arabes Unidos' },
  { value: 'ESP', label: 'España' },
  { value: 'USA', label: 'Estados Unidos de America' },
  { value: 'VAT', label: 'Estado de La Ciudad del Vaticano' },
  { value: 'ERI', label: 'Eritrea' },
  { value: 'EST', label: 'Estonia' },
  { value: 'ETH', label: 'Etiopia' },
  { value: 'SVK', label: 'Eslovaquia' },
  { value: 'SVN', label: 'Eslovenia' },
  { value: 'FJI', label: 'Fiji' },
  { value: 'FIN', label: 'Finlandia' },
  { value: 'FRA', label: 'Francia' },
  { value: 'PHL', label: 'Filipinas' },
  { value: 'GNQ', label: 'Guinea Ecuatorial' },
  { value: 'GUF', label: 'Guayana Francesa' },
  { value: 'GAB', label: 'Gabon' },
  { value: 'GMB', label: 'Gambia' },
  { value: 'GEO', label: 'Georgia' },
  { value: 'GHA', label: 'Ghana' },
  { value: 'GIB', label: 'Gibraltar' },
  { value: 'GRC', label: 'Grecia' },
  { value: 'GRL', label: 'Groenlandia' },
  { value: 'GRD', label: 'Granada' },
  { value: 'GLP', label: 'Guadalupe' },
  { value: 'GUM', label: 'Guam' },
  { value: 'GTM', label: 'Guatemala' },
  { value: 'GGY', label: 'Guernsey' },
  { value: 'GIN', label: 'Guinea' },
  { value: 'GNB', label: 'Guinea-Bissau' },
  { value: 'GUY', label: 'Guyana' },
  { value: 'HTI', label: 'Haiti' },
  { value: 'NLD', label: 'Holanda' },
  { value: 'HND', label: 'Honduras' },
  { value: 'HKG', label: 'Hong Kong' },
  { value: 'HUN', label: 'Hungria' },
  { value: 'ISL', label: 'Islandia' },
  { value: 'IND', label: 'India' },
  { value: 'IDN', label: 'Indonesia' },
  { value: 'IRN', label: 'Iran' },
  { value: 'IRQ', label: 'Iraq' },
  { value: 'IRL', label: 'Irlanda' },
  { value: 'CXR', label: 'Islas Christmas' },
  { value: 'CCK', label: 'Islas Cocos (Keeling)' },
  { value: 'COK', label: 'Islas Cook' },
  { value: 'IMN', label: 'Isla of Man' },
  { value: 'BVT', label: 'Isla Bouvet' },
  { value: 'FLK', label: 'Islas Falkland (Malvinas)' },
  { value: 'FRO', label: 'Islas Faroe' },
  { value: 'SGS', label: 'Islas Georgias del Sur y Sandwich del Sur' },
  { value: 'HMD', label: 'Islas Heard y Mcdonald' },
  { value: 'MNP', label: 'Islas Mariana Septentrionales' },
  { value: 'SLB', label: 'Islas Salomon' },
  { value: 'CYM', label: 'Islas Caimanes' },
  { value: 'MHL', label: 'Islas Marshall' },
  { value: 'TCA', label: 'Islas Turcas y Caicos' },
  { value: 'NFK', label: 'Isla Norfolk' },
  { value: 'UMI', label: 'Islas Remotas Menores de los Estados Unidos' },
  { value: 'VGB', label: 'Islas Virgenes (Britanicas)' },
  { value: 'VIR', label: 'Islas Virgenes (Estados Unidos)' },
  { value: 'WLF', label: 'Islas Wallis y Futuna' },
  { value: 'ALA', label: 'Islas Åland' },
  { value: 'ISR', label: 'Israel' },
  { value: 'ITA', label: 'Italia' },
  { value: 'JAM', label: 'Jamaica' },
  { value: 'JPN', label: 'Japon' },
  { value: 'JEY', label: 'Jersey' },
  { value: 'JOR', label: 'Jordania' },
  { value: 'KAZ', label: 'Kazajstan' },
  { value: 'KEN', label: 'Kenya' },
  { value: 'KIR', label: 'Kiribati' },
  { value: 'PRK', label: 'Korea (Republica Popular Democratica de Corea)' },
  { value: 'KOR', label: 'Korea (Republica de)' },
  { value: 'KWT', label: 'Kuwait' },
  { value: 'KGZ', label: 'Kirguistan' },
  { value: 'LAO', label: 'Lao' },
  { value: 'LVA', label: 'Letonia' },
  { value: 'LBN', label: 'Libano' },
  { value: 'LSO', label: 'Lesotho' },
  { value: 'LBR', label: 'Liberia' },
  { value: 'LBY', label: 'Libya' },
  { value: 'LIE', label: 'Liechtenstein' },
  { value: 'LTU', label: 'Lituania' },
  { value: 'LUX', label: 'Luxemburgo' },
  { value: 'MAC', label: 'Macao' },
  { value: 'MDG', label: 'Madagascar' },
  { value: 'MWI', label: 'Malawi' },
  { value: 'MYS', label: 'Malasia' },
  { value: 'MDV', label: 'Maldivas' },
  { value: 'MLI', label: 'Mali' },
  { value: 'MLT', label: 'Malta' },
  { value: 'MTQ', label: 'Martinica' },
  { value: 'MRT', label: 'Mauritania' },
  { value: 'MUS', label: 'Mauricio' },
  { value: 'MYT', label: 'Mayotte' },
  { value: 'MEX', label: 'México' },
  { value: 'FSM', label: 'Micronesia (Estados Federados De)' },
  { value: 'MDA', label: 'Moldova' },
  { value: 'MCO', label: 'Monaco' },
  { value: 'MNG', label: 'Mongolia' },
  { value: 'MNE', label: 'Montenegro' },
  { value: 'MSR', label: 'Montserrat' },
  { value: 'MAR', label: 'Marruecos' },
  { value: 'MOZ', label: 'Mozambique' },
  { value: 'MMR', label: 'Myanmar' },
  { value: 'NAM', label: 'Namibia' },
  { value: 'NRU', label: 'Nauru' },
  { value: 'NPL', label: 'Nepal' },
  { value: 'NCL', label: 'Nueva Caledonia' },
  { value: 'NZL', label: 'Nueva Zelandia' },
  { value: 'NIC', label: 'Nicaragua' },
  { value: 'NER', label: 'Niger' },
  { value: 'NGA', label: 'Nigeria' },
  { value: 'NIU', label: 'Niue' },
  { value: 'MKD', label: 'Macedonia Norte' },
  { value: 'NOR', label: 'Noruega' },
  { value: 'OMN', label: 'Oman' },
  { value: 'PAK', label: 'Pakistan' },
  { value: 'PLW', label: 'Palau' },
  { value: 'PSE', label: 'Palestine' },
  { value: 'PAN', label: 'Panama' },
  { value: 'PNG', label: 'Papua Nueva Guinea' },
  { value: 'PRY', label: 'Paraguay' },
  { value: 'PER', label: 'Peru' },
  { value: 'PCN', label: 'Pitcairn' },
  { value: 'POL', label: 'Polonia' },
  { value: 'PYF', label: 'Polinesia Francesa' },
  { value: 'PRT', label: 'Portugal' },
  { value: 'PRI', label: 'Puerto Rico' },
  { value: 'QAT', label: 'Qatar' },
  { value: 'ROU', label: 'Romania' },
  { value: 'RUS', label: 'Rusia' },
  { value: 'RWA', label: 'Rwanda' },
  { value: 'GBR', label: 'Reino Unido (Ciudadano Britanico)' },
  { value: 'REU', label: 'Réunion' },
  { value: 'CZE', label: 'Republica Checa' },
  { value: 'DOM', label: 'Republica Dominicana' },
  { value: 'SYR', label: 'Republica Arabe Siria' },
  { value: 'CAF', label: 'Republica Centro Africana' },
  { value: 'BLM', label: 'Saint Barthélemy' },
  { value: 'ESH', label: 'Sahara Occidental' },
  { value: 'SHN', label: 'Santa Helena' },
  { value: 'KNA', label: 'Saint Kitts y Nevis' },
  { value: 'LCA', label: 'Saint Lucia' },
  { value: 'MAF', label: 'San Martín (parte francesa' },
  { value: 'SPM', label: 'Saint Pierre y Miquelon' },
  { value: 'VCT', label: 'San Vicente y las Granadinas' },
  { value: 'WSM', label: 'Samoa' },
  { value: 'ASM', label: 'Samoa Estadounidense' },
  { value: 'SMR', label: 'San Marino' },
  { value: 'STP', label: 'Santo Tome y Principe' },
  { value: 'SEN', label: 'Senegal' },
  { value: 'SRB', label: 'Serbia' },
  { value: 'SYC', label: 'Seychelles' },
  { value: 'SLE', label: 'Sierra Leona' },
  { value: 'SGP', label: 'Singapur' },
  { value: 'SXM', label: 'San Martín (Países Bajos)' },
  { value: 'SOM', label: 'Somalia' },
  { value: 'ZAF', label: 'Sudafrica' },
  { value: 'SSD', label: 'Sudán del Sur' },
  { value: 'LKA', label: 'Sri Lanka' },
  { value: 'SDN', label: 'Sudan' },
  { value: 'SUR', label: 'Suriname' },
  { value: 'SJM', label: 'Svalbard y Jan Mayen' },
  { value: 'SWE', label: 'Suecia' },
  { value: 'CHE', label: 'Suiza' },
  { value: 'SWZ', label: 'Swazilandia' },
  { value: 'TWN', label: 'Taiwan' },
  { value: 'TJK', label: 'Tajikistan' },
  { value: 'TZA', label: 'Tanzania, Republica Unida de' },
  { value: 'THA', label: 'Tailandia' },
  { value: 'ATF', label: 'Territorios Franceses del Sur' },
  { value: 'IOT', label: 'Territorio Britanico del Oceano Indico' },
  { value: 'TLS', label: 'Timor-Leste' },
  { value: 'TGO', label: 'Togo' },
  { value: 'TKL', label: 'Tokelau' },
  { value: 'TON', label: 'Tonga' },
  { value: 'TTO', label: 'Trinidad y Tobago' },
  { value: 'TUN', label: 'Tunez' },
  { value: 'TUR', label: 'Turquia' },
  { value: 'TKM', label: 'Turkmenistan' },
  { value: 'TUV', label: 'Tuval' },
  { value: 'UGA', label: 'Uganda' },
  { value: 'UKR', label: 'Ucrania' },
  { value: 'URY', label: 'Uruguay' },
  { value: 'UZB', label: 'Uzbekistan' },
  { value: 'VUT', label: 'Vanuatu' },
  { value: 'VEN', label: 'Venezuela' },
  { value: 'VNM', label: 'Vietnam' },
  { value: 'YEM', label: 'Yemen' },
  { value: 'ZMB', label: 'Zambia' },
  { value: 'ZWE', label: 'Zimbabwe' },
];

export default countries;

