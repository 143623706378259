import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';

export const financialGoals = async (prospectId, goals, urls) => {
  if (!goals) {
    throw new Error('A goal must be selected');
  }

  const discoveryURLs = urls || await fetchDiscoveryURLs();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.prospect_financial_goals) {
    throw new Error('Discovery fetch response unexpected - missing endpoint');
  }

  const payload = {
    'prospect-id': prospectId,
    subject: 'financial-goals',
    objectives: goals,
  };

  const result = await fetchJson(discoveryURLs.prospect_financial_goals, {
    method: 'POST',
    body: payload,
  });

  return result;
};

