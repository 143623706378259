import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';

export const validateCURP = async (citizenId, urls, token) => {
  if (!citizenId) {
    throw new Error('Citizen id expected');
  }

  const discoveryURLs = urls || await fetchDiscoveryURLs();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.citizen_id_sync_validation) {
    throw new Error('Discovery fetch response unexpected - missing endpoint');
  }

  const payload = {
    'citizen-id': citizenId, // CURP
  };

  const reCaptchaHeaders = {
    // 'x-recaptcha-token-type': 'enterprise' or 'v3'
    'x-recaptcha-token-type': 'enterprise',
    'x-recaptcha-token': token,
  };
  try {
    const result = await fetchJson(discoveryURLs.citizen_id_sync_validation, {
      method: 'POST',
      body: payload,
      ...(token && { headers: reCaptchaHeaders }),
    });
    return result;
  } catch (error) {
    return error;
  }
};
