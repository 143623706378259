import styled from 'styled-components';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

const ProgressBarContainer = styled.div`
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  width: 100%;
  background-color: ${nuDSColor('white', 'dark')};
`;

ProgressBarContainer.displayName = 'ProgressBarContainer';

export default ProgressBarContainer;
