import React, { useEffect } from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled from 'styled-components';

import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { cuentaRTREvent } from '../../../../tracking';
import { FormStepContainer } from '../../../../styles/FormStepContainer';
import useMobileOS from '../../../../../../utils/hooks/useMobileOS';
import { StyledButton } from '../../styles/StyledButton';
import { StyledHeader } from '../../styles/Header';

const StyledTitle = styled(Typography)`
  font-size: 28px;

  @media (width >= 768px) {
    font-size: 2.1rem;
  }
`;

const Line = styled(Box)`
  border: 1px solid rgba(17 17 17 / 10%);
  margin-top: 5px;
  width: 97%;
`;

function CuentaOnlyXp() {
  const { closeRegistrationForm } = useSiteContext();
  const mobileSystem = useMobileOS();

  const getOSLink = () => {
    if (mobileSystem === 'ios') {
      window.open('https://link.nubank.com.br/5YMt1FwBMBb', '_blank');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.nu.production&listing=ric', '_blank');
    }
  };

  useEffect(() => {
    cuentaRTREvent();
  }, []);

  return (
    <FormStepContainer height="100%">
      <StyledHeader>
        <Button
          variant="basic"
          onClick={() => closeRegistrationForm(true)}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </StyledHeader>

      {/* CHECK ICON */}
      <Image
        src="one-step/rtr/green-check.svg"
        alt="Ilustracion de un icono verde con una palomita"
        width="150px"
        webp={false}
      />

      {/* TITLE AND SUBTITLE */}
      <StyledTitle
        variant="heading2"
        marginTop="8x"
      >
        <StyledTitle
          tag="span"
          variant="heading2"
          color="primary"
        >
          👉 Descarga la app
          {' '}
        </StyledTitle>

        para contratar Nu
      </StyledTitle>

      <Typography
        variant="subtitle1"
        marginTop="6x"
        marginBottom="6x"
      >
        Todo está listo para que
        <Typography strong tag="span" variant="subtitle1">
          {' '}
          continúes en la app con el mismo correo y el número celular
          {' '}
        </Typography>
        que nos diste en tu registro.
      </Typography>

      {/* BENEFITS */}
      <Box marginBottom="4x">
        <Typography variant="heading4" strong color="primary">
          Qué beneficios te da Nu:
        </Typography>

        <Line tag="hr" />

        <Box
          marginTop="4x"
          display="flex"
          flexDirection="row"
        >
          <Image
            alt="Icono de corazón"
            src="one-step/rtr/heart.svg"
            webp={false}
            width="24px"
          />
          <Typography
            variant="paragraph1"
            marginLeft="4x"
            marginTop="-5px"
            strong
          >
            Sin comisiones por uso mínimo.
          </Typography>
        </Box>
        <Box
          marginTop="4x"
          display="flex"
          flexDirection="row"
        >
          <Image
            alt="Icono de carita feliz"
            src="one-step/rtr/smiley.svg"
            webp={false}
            width="24px"
          />
          <Typography
            variant="paragraph1"
            marginLeft="4x"
            marginTop="-2px"
          >
            Atención
            <Typography tag="span" strong variant="paragraph1">
              {' '}
              humana y soporte las 24/7.
            </Typography>
          </Typography>
        </Box>
        <Box
          marginTop="4x"
          display="flex"
          flexDirection="row"
        >
          <Image
            alt="Icono de escudo"
            src="one-step/rtr/shield.svg"
            webp={false}
            width="36px"
          />
          <Typography
            variant="paragraph1"
            marginLeft="4x"
          >
            Tecnología para
            <Typography tag="span" strong variant="paragraph1">
              {' '}
              cuidar que solo tú apruebes
              {' '}
            </Typography>
            y sepas de cada transacción.
          </Typography>
        </Box>

      </Box>

      {/* DOWNLOAD BUTTON */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="4x"
        marginTop="12x"
      >
        <StyledButton
          iconProps={{ name: 'arrow-right' }}
          extended
          type="button"
          onClick={() => getOSLink()}
        >
          Descargar App Nu
        </StyledButton>
      </Box>

    </FormStepContainer>
  );
}

export default CuentaOnlyXp;
