import { getFilteredCompoundLastName, getFilteredCompoundName, standardizeString } from './helpers';

export function getNormalizedNames(names) {
  const standardizedNames = standardizeString(names);
  const filteredNames = getFilteredCompoundName(standardizedNames);
  const normalizedNames = getFilteredCompoundLastName(filteredNames);

  return normalizedNames;
}

export function getNormalizedLastNames(lastname) {
  const standardizedFirstSurname = standardizeString(lastname);
  const normalizedLastnames = getFilteredCompoundLastName(standardizedFirstSurname);

  return normalizedLastnames;
}

export function normalizeNamesAndLastnames(names, firstSurname, secondSurname) {
  const normalizedNames = getNormalizedNames(names);
  const normalizedFirstSurname = getNormalizedLastNames(firstSurname);
  const normalizedSecondSurname = getNormalizedLastNames(secondSurname);

  return { normalizedNames, normalizedFirstSurname, normalizedSecondSurname };
}
