import { v4 as uuid } from 'uuid';

import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';
import { cookieStorage } from '@nubank/www-latam-commons/utils/storage';

import {
  PROSPECT_VALUE_COOKIE_NAME,
  AUTHORIZATION_REQUEST_COOKIE_NAME,
} from '../../utils/cookies';

const AN_HOUR_IN_DAY_FRAGMENT = 1 / 24;

const EXPIRATION_IN_DAYS = AN_HOUR_IN_DAY_FRAGMENT;

const cleanPhone = phone => `52${phone.split(' ').join('')}`;

function getPersistedAuthorizationRequestData() {
  const persistedProspectValue = cookieStorage.get(PROSPECT_VALUE_COOKIE_NAME);
  const persistedRequestId = cookieStorage.get(AUTHORIZATION_REQUEST_COOKIE_NAME);

  if (persistedProspectValue && persistedRequestId) {
    return {
      persistedProspectValue,
      persistedRequestId,
    };
  }

  return undefined;
}

export function getCurrentAuthorizationRequestId(prospectValue) {
  const persistedData = getPersistedAuthorizationRequestData();

  if (persistedData) {
    if (persistedData.persistedProspectValue === prospectValue) {
      return persistedData.persistedRequestId;
    }
  }

  return null;
}

function persistAuthorizationRequestData(authorizationRequestId, prospectValue) {
  cookieStorage.set(AUTHORIZATION_REQUEST_COOKIE_NAME, authorizationRequestId, EXPIRATION_IN_DAYS);
  cookieStorage.set(PROSPECT_VALUE_COOKIE_NAME, prospectValue, EXPIRATION_IN_DAYS);
}

function createAuthorizationRequestPayload({ phone }) {
  return {
    authorization_request: {
      flow_id: uuid(),
      phone: cleanPhone(phone),
    },
    notification_channels: ['sms', 'whatsapp'],
  };
}

function resendAuthorizationRequestPayload({ id }) {
  return {
    authorization_request_resend: {
      id,
      flow_id: uuid(),
    },
    notification_channels: ['sms', 'whatsapp'],
  };
}

export async function resendAuthorizationRequest(id, urls, includeOriginURL = true) {
  const discoveryURLs = urls || await fetchDiscoveryURLs();

  const currentURLPath = window.location.href;

  const authorizationRequestDocument = await fetchJson(
    discoveryURLs.bureau_authorization_request_resend,
    {
      method: 'PUT',
      body: {
        ...resendAuthorizationRequestPayload({ id }),
        ...(includeOriginURL && { origin_url: currentURLPath }),
      },
    },
  );

  return authorizationRequestDocument.authorization_request;
}

export async function createAuthorizationRequest({
  phone, urls, includeOriginURL = true,
}) {
  const discoveryURLs = urls || await fetchDiscoveryURLs();
  const currentURLPath = window.location.href;

  const persistedRequestId = getCurrentAuthorizationRequestId(phone);

  if (persistedRequestId) {
    const authorizationRequest = await resendAuthorizationRequest(
      persistedRequestId,
      discoveryURLs,
      includeOriginURL,
    );

    return authorizationRequest;
  }

  const { authorization_request: authorizationRequest } = await fetchJson(
    discoveryURLs.bureau_authorization_request_create,
    {
      method: 'POST',
      body: {
        ...createAuthorizationRequestPayload({ phone }),
        ...(includeOriginURL && { origin_url: currentURLPath }),
      },
    },
  );

  if (authorizationRequest) {
    persistAuthorizationRequestData(authorizationRequest.id, phone);
    return authorizationRequest;
  }
  throw new Error('Invalid response');
}

export async function checkAuthorizationRequest({
  id, nip, phone, urls,
}) {
  const discoveryURLs = urls || await fetchDiscoveryURLs();

  const authorizationRequestDocument = await fetchJson(
    discoveryURLs.bureau_authorization_request_check,
    {
      method: 'PUT',
      body: {
        authorization_request: {
          id,
          nip,
          phone: cleanPhone(phone),
        },
      },
    },
  );

  if (authorizationRequestDocument.authorization_request) {
    return authorizationRequestDocument.authorization_request;
  }
  throw new Error('Invalid response');
}

export async function confirmAuthorizationRequest({
  id, nip, rfc, phone, urls,
}) {
  const discoveryURLs = urls || await fetchDiscoveryURLs();

  const authorizationRequestDocument = await fetchJson(
    discoveryURLs.bureau_authorization_request_confirm,
    {
      method: 'POST',
      body: {
        authorization_request: {
          id,
          nip,
          rfc: rfc.toUpperCase(),
          phone: cleanPhone(phone),
        },
      },
    },
  );

  if (authorizationRequestDocument.authorization_request) {
    return authorizationRequestDocument.authorization_request;
  }
  throw new Error('Invalid response');
}

export async function assignProspectToAuthRequest({ prospectId, authorizationRequestId, urls }) {
  const discoveryURLs = urls || await fetchDiscoveryURLs();

  const prospectAuthRequestVerified = await fetchJson(
    discoveryURLs.bureau_authorization_assign_prospect, {
      method: 'PUT',
      body: {
        id: authorizationRequestId,
        'prospect-id': prospectId,
      },
    },
  );

  if (prospectAuthRequestVerified.authorization_request) {
    return prospectAuthRequestVerified;
  }

  throw new Error('Invalid response');
}
