import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Image from '@nubank/nuds-web/components/Image/Image';
import Box from '@nubank/nuds-web/components/Box/Box';

import { useSiteContext } from '../../../../components/SiteContext/SiteContext';
import { deniedScreenRegisterEvent } from '../../tracking';

import { DeniedScreenContainer } from './styles/DeniedScreenContainer';
import { StyledHeader } from './styles/Header';

const DeniedScreen = ({ name, email }) => {
  const { closeRegistrationForm } = useSiteContext();
  const [firstName] = name.split(' ');

  const handleCloseBtn = () => {
    closeRegistrationForm(true);
  };

  useEffect(() => {
    deniedScreenRegisterEvent();
  }, []);

  return (
    <DeniedScreenContainer>
      <StyledHeader>
        <Button
          variant="basic"
          styleVariant="primary"
          onClick={handleCloseBtn}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </StyledHeader>

      <Image
        src="one-step/card-wait-notice.svg"
        alt="Un icono de un sobre de mensaje morado"
        webp={false}
        width="150px"
      />

      <Typography gutterBottom={1} variant="heading2">
        ¡Listo!
      </Typography>

      <Typography gutterBottom={2} variant="subtitle1">
        <FormattedMessage
          id="PROSPECT_REGISTRATION_FORM.DENIED_SUBTITLE"
          values={{
            strong: chunks => <strong>{chunks}</strong>,
            userName: firstName,
            userEmail: email,
          }}
        />
      </Typography>

      <Box marginTop="auto">
        <Button
          variant="contained"
          styleVariant="primary"
          extended
          onClick={handleCloseBtn}
          intlKey="PROSPECT_REGISTRATION_FORM.DENIED_SCREEN.FINISH_BUTTON_LABEL"
        />
      </Box>

    </DeniedScreenContainer>
  );
};

DeniedScreen.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default DeniedScreen;
