import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import Box from '@nubank/nuds-web/components/Box/Box';

export const FormStepContainer = styled(Box)`
  overflow-y: auto;

  ${breakpointsMedia({
    lg: css`
      padding: 2.5rem;
    `,
  })}

`;

FormStepContainer.defaultProps = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  padding: '1.5rem',
};

FormStepContainer.displayName = 'FormStepContainer';
