import { isType } from '@nubank/nuds-web/utils/isType/isType';
import isEmpty from '@nubank/nuds-web/utils/string/isEmpty';

import { cleanMXPhone } from './cleanPhone';
import { createDocument } from './createDocument';
import { formatDOBWithHypens } from './formatDOBWithHypens';
import { mapCurpStates } from './mapCurpStates';
import { mapGenderValue } from './mapGenderValue';

export const mapFormPospectToWire = formValues => {
  const formattedDOB = formatDOBWithHypens(formValues.year, formValues.month, formValues.day);

  const wireProspect = {
    given_names: formValues.names,
    first_surname: formValues.firstSurname,
    second_surname: (formValues.secondSurname || ''),
    dob: formattedDOB,
    cpf: formValues.taxId.toUpperCase(),
    email: formValues.email,
    billing_address_postcode: formValues.postCode,
    billing_address_state: formValues.addressState,
    billing_address_city: formValues.city,
    billing_address_locality: formValues.locality,
    billing_address_line1: formValues.street,
    billing_address_number: formValues.streetNumberExt,
    phone: cleanMXPhone(formValues.phone),
    gender: mapGenderValue(formValues.gender),
    ...formValues.countryOfBirth && { country_of_birth: formValues.countryOfBirth },
    ...formValues.curp && { documents: [createDocument('curp', formValues.curp)] },
  };

  if (!isEmpty(formValues.streetNumberInt)) {
    wireProspect.billing_address_line2 = formValues.streetNumberInt;
  }

  if (!isEmpty(formValues.entity) && formValues.entity !== 'NE') {
    wireProspect.state_of_birth = mapCurpStates(formValues.entity);
  }

  if (formValues.prospectUsedManualInputs) {
    wireProspect.tags = ['invalid-address'];
  }

  Object.keys(wireProspect).forEach(key => {
    if (isType('string', wireProspect[key])) {
      wireProspect[key] = wireProspect[key].trim();
    }
  });

  return wireProspect;
};

