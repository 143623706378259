import React, { useEffect } from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';

import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { FormStepContainer } from '../../../../styles/FormStepContainer';
import { StyledHeader } from '../../styles/Header';
import { approvedScreenRegisterViewEvent, creditCardRTREvent } from '../../../../tracking';
import { useDynamicYieldValues } from '../../../../../../utils/dynamicYield';
import { useRegistrationFormContext } from '../../../RegistrationForm/RegistrationForm';
import { generateBranchLink } from '../../../../../../utils/branchIO/generateBranchLink';

const StyledTitle = styled(Typography)`
  font-size: 28px;

  @media (width >= 768px) {
    font-size: 2.1rem;
  }
`;

const StyledBox = styled(Box)`
  gap: 8px;
  
  p {
    flex: 1;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
`;

function MultiproductMagic() {
  const { email, phone, closeRegistrationForm } = useSiteContext();
  const { values: dynamicYieldValues } = useDynamicYieldValues();
  const { prospectRegistrationData } = useRegistrationFormContext();

  useEffect(() => {
    approvedScreenRegisterViewEvent();
    creditCardRTREvent();
  }, []);

  const navigateToMagicLink = () => {
    window.open(generateBranchLink({ prospectId: prospectRegistrationData.prospectId }), '_blank'); // Opens in a new tab
  };

  return (
    <FormStepContainer height="100%">
      <StyledHeader>
        <Button
          variant="basic"
          onClick={() => closeRegistrationForm(true)}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </StyledHeader>

      {/* TITLE AND SUBTITLE */}
      <StyledTitle
        variant="heading2"
        marginTop="6x"
      >
        ¡Ya tenemos tu oferta lista en la app! 🥳️
      </StyledTitle>

      <Typography
        variant="subtitle1"
        marginTop="4x"
        marginBottom="6x"
        whiteSpace="pre-line"
        strong
      >
        <Typography strong tag="span" variant="subtitle1" color="primary">
          Continúa
        </Typography>
        {' para terminar de contratar tu oferta.'}
      </Typography>

      <Typography color="#000000A3" variant="subtitle2">
        {'Si ya tienes la app continúa el registro con el correo '}
        <Typography tag="span" color="primary" strong variant="subtitle2">
          {email }
        </Typography>
        {' y el celular '}
        <Typography tag="span" color="primary" strong variant="subtitle2">
          {`** ****${phone.slice(8)}`}
        </Typography>
        .
      </Typography>

      {/* MAGIC LINK BUTTON */}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="4x"
        marginTop="8x"
      >
        <StyledButton
          iconProps={{ name: 'arrow-right' }}
          extended
          type="button"
          onClick={() => navigateToMagicLink()}
        >
          Continuar en la app
        </StyledButton>
      </Box>

      {/* IMAGE BLOCK */}

      <Typography variant="subtitle1" strong>
        Tu oferta te está esperando:
      </Typography>

      <StyledBox
        marginTop="6x"
        marginBottom="6x"
        display="flex"
        flexDirection="row"
        alignItems="center"
        paddingRight={{ xs: '24px', md: '48px' }}
      >
        <Image
          alt="Icono de una tarjeta"
          src="one-step/rtr/credit-card-magic.png"
          webp
          width="98px"
          height="99px"
        />

        <Typography variant="paragraph1" strong>
          Tarjeta de Crédito Nu
          <Typography variant="paragraph1">
            $0 anualidad, MSI y opciones de pago flexibles.
          </Typography>

        </Typography>
      </StyledBox>

      <StyledBox
        marginTop="2x"
        marginBottom="6x"
        display="flex"
        flexDirection="row"
        alignItems="center"
        paddingRight="60px"
      >
        <Image
          alt="Icono de una tarjeta"
          src="one-step/rtr/debit-card-magic.png"
          webp
          width="98px"
          height="99px"
        />

        <Typography variant="paragraph1" strong>
          Cuenta Nu:
          <Typography variant="paragraph1" tag="span">
            {' '}
            Débito y en la app puedes crecer tus ahorros
            {' '}
          </Typography>
          {`${dynamicYieldValues.dynamicYield}% al año`}
        </Typography>
      </StyledBox>

      {/* CAT LINK */}

      <Box
        display="flex"
        justifyContent="left"
        marginTop="8x"
      >
        <Link
          href="/gat"
          variant="action"
          typographyProps={{ variant: 'paragraph1' }}
          target="_blank"
        >
          Conoce GAT Real y Nominal de Cuenta
        </Link>
      </Box>

    </FormStepContainer>
  );
}

export default MultiproductMagic;
