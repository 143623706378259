import React, { useState, useEffect } from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';
import { getPolling, startRealtimeAnalysis } from '@nubank/www-latam-commons/services/prospect';
import { sendRegisterProspectEvent } from '@nubank/www-latam-commons/services/analytics';

import { useRegistrationFormContext } from '../RegistrationForm/RegistrationForm';
import { useSiteContext } from '../../../../components/SiteContext/SiteContext';
import { ERROR_SEVERITY, sentryException } from '../../../../utils/sentry';

import DeniedScreen from './DeniedScreen';
import LoadingScreen from './LoadingScreen';
import CuentaOnly from './rtr/CuentaOnly';
import SecuredCard from './rtr/SecuredCard';
import Multiproduct from './rtr/Multiproduct';
import CuentaOnlyXp from './rtr/experiment/CuentaOnlyXp';
import SecuredCardXp from './rtr/experiment/SecuredCardXp';
import MultiproductXp from './rtr/experiment/MultiproductXp';
import CuentaOnlyMagic from './rtr/magic-link/CuentaOnlyMagic';
import SecuredCardMagic from './rtr/magic-link/SecuredCardMagic';
import MultiproductMagic from './rtr/magic-link/MultiproductMagic';

const SCREENS = {
  APPROVED: 'APPROVED',
  NEUTRAL: 'NEUTRAL',
};

const RTR_EXPERIMENT_ID = '343918';
const RTR_EXPERIMENT_VARIANT = '45780';

const MAGIC_LINK_XP_ID = '344507';
const MAGIC_LINK_XP_VARIANT_1_ID = '46652';

const DecisionStep = () => {
  // RTR Experiment
  const activeVariant = useFigPiiExperiment(RTR_EXPERIMENT_ID);
  // Magic Link Experiment
  const magicLinkVariant = useFigPiiExperiment(MAGIC_LINK_XP_ID);

  const { email } = useSiteContext();
  const { prospectRegistrationData } = useRegistrationFormContext();
  const [analisisResult, setAnalisisResult] = useState(false);
  const [analyzingPercentage, setAnalyzingPercentage] = useState(10);
  const [currentScreen, setCurrentScreen] = useState(null);

  const updateRealtimeAnalysis = percentage => {
    setAnalyzingPercentage(percentage);
  };

  const handleProspectRegistration = async () => {
    const {
      hasThrottle,
      marketingId,
      hasRealtimeAnalysis,
      realtimeUrl,
      prospectType,
    } = prospectRegistrationData;

    try {
      if (hasThrottle) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      sendRegisterProspectEvent(prospectType, marketingId);

      if (!hasRealtimeAnalysis) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      const polling = await getPolling(realtimeUrl);

      if (!polling) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      const analisis = await startRealtimeAnalysis({
        url: realtimeUrl,
        onProgress: updateRealtimeAnalysis,
        polling,
      });

      updateRealtimeAnalysis(100);

      if (!analisis || !analisis.template) {
        setCurrentScreen(SCREENS.NEUTRAL);
        return;
      }

      setAnalisisResult(analisis.template);

      if (analisis.template.id === 'no_products') {
        setTimeout(() => setCurrentScreen(SCREENS.NEUTRAL), 1200);
      } else {
        setTimeout(() => setCurrentScreen(SCREENS.APPROVED), 1200);
      }
    } catch (error) {
      setCurrentScreen(SCREENS.NEUTRAL);
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'decision step',
        namespace: '<DecisionStep />',
        level: ERROR_SEVERITY.CRITICAL,
      });
    }
  };

  return (
    <Form.Step isForm={false}>
      {({ formsValues }) => {
        useEffect(() => {
          handleProspectRegistration();
        }, []);

        if (currentScreen === 'APPROVED') {
        /// ////////////////////////// CUENTA ONLY////////////////////////////////////////
          if (analisisResult.id === 'savings_account') {
            if (magicLinkVariant === MAGIC_LINK_XP_VARIANT_1_ID) {
              return <CuentaOnlyMagic />;
            }

            if (activeVariant === RTR_EXPERIMENT_VARIANT) {
              return <CuentaOnlyXp />;
            }

            return <CuentaOnly email={email} />;
          }

          /// ////////////////////////// SECURED CARD////////////////////////////////////////
          if (analisisResult.id === 'secured_credit_card_and_nuconta') {
            if (magicLinkVariant === MAGIC_LINK_XP_VARIANT_1_ID) {
              return <SecuredCardMagic />;
            }

            if (activeVariant === RTR_EXPERIMENT_VARIANT) {
              return <SecuredCardXp />;
            }

            return <SecuredCard email={email} />;
          }

          // ///////////////////////////// MULTIPRODUCT ////////////////////////////////////////
          if (magicLinkVariant === MAGIC_LINK_XP_VARIANT_1_ID) {
            return <MultiproductMagic />;
          }

          if (activeVariant === RTR_EXPERIMENT_VARIANT) {
            return <MultiproductXp />;
          }

          return <Multiproduct email={email} />;
        }

        /// ////////////////////////// DENIED/NEUTRAL ////////////////////////////////////////
        if (currentScreen === 'NEUTRAL') {
          return (
            <DeniedScreen name={formsValues.names} email={email} />
          );
        }

        /// ////////////////////////// LOADING ////////////////////////////////////////
        return (
          <LoadingScreen percentage={analyzingPercentage} />
        );
      }}
    </Form.Step>
  );
};

export default DecisionStep;
