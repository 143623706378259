export const useDynamicYieldValues = () => {
  const dynamicState = {
    values: {
      dynamicYield: '12.50',
      dynamicGATNominal: '13.31',
      dynamicGATReal: '9.21',
      // 7 DAYS
      dynamicYield7Days: '12.75',
      dynamicGATNominal7Days: '13.60',
      dynamicGATReal7Days: '9.48',
      // 28 DAYS
      dynamicYield28Days: '13.12',
      dynamicGATNominal28Days: '14.02',
      dynamicGATReal28Days: '9.89',
      // 90 days
      dynamicYield90Days: '14.50',
      dynamicGATNominal90Days: '15.60',
      dynamicGATReal90Days: '11.41',
      // 180 days
      dynamicYield180Days: '12.36',
      dynamicGATNominal180Days: '13.15',
      dynamicGATReal180Days: '9.05',
    },
    textTemplates: {
      VALUES_CALCULATED_AT: '27 de septiembre de 2024',
      MINIMUM_SAVINGS_AMOUNT: '$0.01',
      FROZEN_MINIMUM_SAVINGS_AMOUNT: '$50.00',
      VALID_UNTIL: '27 de noviembre de 2024',
    },
  };

  return dynamicState;
};

export const FROZEN_TIME = {
  A_180_DAYS: 180,
  A_90_DAYS: 90,
  A_28_DAYS: 28,
  A_7_DAYS: 7,
  NO_FROZEN: 0,
};
