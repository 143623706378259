import styled from 'styled-components';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

const Filler = styled.div`
  background: ${nuDSColor('primary')};
  height: 100%;
  border-radius: inherit;
  transition: width .6s ease-in;
  width: ${({ width }) => `${width}%`};
`;

Filler.displayName = 'Filler';

export default Filler;
