import styled from 'styled-components';

export const StyledHeader = styled.header`
  display: flex;
  justify-content: flex-end;

  & > button {
      height: 3rem;
    }
`;

StyledHeader.displayName = 'StyledHeader';
