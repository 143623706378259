import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';

export const getGeoCitiesList = async (state, urls) => {
  if (!state) {
    throw new Error('user selected state is required');
  }

  const discoveryURLs = urls || await fetchDiscoveryURLs();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.geo_state) {
    throw new Error('Discovery fetch geo_city response unexpected - missing endpoint');
  }

  const result = await fetchJson(discoveryURLs.geo_city, {
    method: 'POST',
    body: {
      country: 'MX', // only works for mx
      state,
    },
  });

  return result;
};
