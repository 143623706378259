import styled from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

export const StyledTitle = styled(Typography)`
  @media (width >= 768px) {
    font-size: 2.1rem;
    
    span {
      font-size: 2.1rem;
    }
  }
`;
