const homoMap = {
  ' ': '00',
  0: '00',
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09',
  '&': '10',
  A: '11',
  B: '12',
  C: '13',
  D: '14',
  E: '15',
  F: '16',
  G: '17',
  H: '18',
  I: '19',
  J: '21',
  K: '22',
  L: '23',
  M: '24',
  N: '25',
  O: '26',
  P: '27',
  Q: '28',
  R: '29',
  S: '32',
  T: '33',
  U: '34',
  V: '35',
  W: '36',
  X: '37',
  Y: '38',
  Z: '39',
  Ñ: '40',
};
const GOVERNMENT_FACTOR_HOMOCLAVE = 34;

const digits = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';

// Important: We should remove every accent except the Ñ: charcode(u0303)
function removeAccentsFromFullName(input) {
  const beforeEneRegex = /[\u0300-\u0302]/g;
  const afterEneRegex = /[\u0304-\u036f]/g;
  const specialEneRegexAccent = /N\u0303/g;
  const specialCharactersRegex = /[-.',]/g;

  return input
    .toUpperCase()
    .normalize('NFD')
    .replace(beforeEneRegex, '')
    .replace(afterEneRegex, '')
    .replace(specialEneRegexAccent, 'Ñ')
    .replace(specialCharactersRegex, '');
}

function mapCharacterToTwoDigitsCode(code) {
  return homoMap[code];
}

function sumPairsOfDigits(input) {
  return input
    .split('')
    .reduce((total, current, idx, arr) => {
      const secondNumber = arr[idx + 1];

      if (!secondNumber) return total;

      const firstPair = `${current + secondNumber}`;
      return total + parseInt(firstPair, 10) * parseInt(secondNumber, 10);
    }, 0);
}

export default function calculateHomoclave(names, firstSurname, secondSurname) {
  const fullName = `${names} ${firstSurname} ${secondSurname}`;

  const mappedFullName = `0${
    removeAccentsFromFullName(fullName)
      .split('')
      .map(mapCharacterToTwoDigitsCode)
      .join('')}`;

  const sum = sumPairsOfDigits(mappedFullName);
  const lastThreeDigits = sum % 1000;
  const quo = lastThreeDigits / GOVERNMENT_FACTOR_HOMOCLAVE;
  const reminder = lastThreeDigits % GOVERNMENT_FACTOR_HOMOCLAVE;

  return digits.charAt(quo) + digits.charAt(reminder);
}
