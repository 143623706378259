import React from 'react';
import PropTypes from 'prop-types';

export default function Spinner({ color }) {
  return (
    <svg
      width="24px"
      viewBox="0 0 38 38"
      overflow="visible"
    >
      <g
        transform="translate(1 1)"
        fill="none"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="4px"
        strokeOpacity="0.5"
      >
        <circle strokeOpacity={0.5} cx={18} cy={18} r={18} />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  );
}

Spinner.defaultProps = {
  color: 'rgba(17,17,17,0.2)',
};

Spinner.propTypes = {
  color: PropTypes.string,
};

