import React from 'react';
import styled from 'styled-components';
import Form from '@nubank/nuds-web/components/Form/Form';
import Checkbox from '@nubank/nuds-web/components/Checkbox/Checkbox';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';

import { useSiteContext } from '../../../../components/SiteContext/SiteContext';
import { useRegistrationFormContext } from '../RegistrationForm/RegistrationForm';
import { financialGoals } from '../../../../domains/financialGoals/financialGoals';
import { ERROR_SEVERITY, sentryException } from '../../../../utils/sentry';
import { financialGoalsRegisterEvent } from '../../tracking';

const StyledTitle = styled(Typography)`
  font-size: 24px;

  @media (width >= 768px) {
    font-size: 1.8rem;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  background: ${props => (props.active ? '#ECD9FF' : '#EFEFEF')};
  box-shadow: ${props => (props.active ? '0px 4px 4px rgba(0 0 0 / 25%)' : 'none')};
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;

  label > span, label > span:checked, label > span:hover,
  label > span:active, label > span:focus,
  label > span:visited, label > span:focus-visible,
  label > span:target {
    background: ${props => (props.active ? '#ECD9FF' : '#EFEFEF')};
    color: ${props => (props.active ? '#820AD1' : '#B8B8B8')};
  }
 
  label > span:last-child {
    color: ${props => (props.disabled ? '#B9B9B9' : 'black')};
    font-size: 14px;
    font-weight: ${props => (props.disabled ? 400 : 'bold')};
  }

  span > svg {
    background: ${props => (props.disabled ? '#EFEFEF' : 'white')};
    border-radius: 7px;
    color: ${props => (props.active ? '#820AD1' : '#B9B9B9')};
  }

  span > svg > g > path {
    color: ${props => (props.disabled ? '#E5E5E5' : 'auto')};
  }

`;

function FinancialGoals() {
  const {
    registrationFlowEvents, updateRegistrationFlowEvents, discoveryUrlsList,
  } = useSiteContext();
  const {
    prospectRegistrationData,
  } = useRegistrationFormContext();

  const handleSubmit = ({ nextStep, setSubmitting, values }) => {
    // Backend keys
    // credit-card-without-annuity
    // liquidity-savings
    // build-credit-history
    // improve-credit-history

    const selectedGoals = Object.keys(values).filter(key => values[key]);

    try {
      financialGoals(prospectRegistrationData.prospectId, selectedGoals, discoveryUrlsList);
      setSubmitting(true);

      if (!registrationFlowEvents.financialGoals) {
        financialGoalsRegisterEvent();
        updateRegistrationFlowEvents({ ...registrationFlowEvents, financialGoals: true });
      }
    } catch (error) {
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'fiancial_goals_step',
        namespace: '<FinancualGoals />',
        level: ERROR_SEVERITY.CRITICAL,
      });
      setSubmitting(false);
    }
    nextStep();
  };

  return (
    <Form.Step
      initialValues={{
        'credit-card-without-annuity': false,
        'liquidity-savings': false,
        'build-credit-history': false,
        'improve-credit-history': false,
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        currentStepValues,
      }) => (
        <Box
          overflowY="auto"
          paddingTop={{ xs: '24px', lg: '40px' }}
          paddingBottom="4x"
          paddingHorizontal={{ xs: '24px', lg: '40px' }}
          position="relative"
          minHeight="100%"
        >
          {/* TITLE AND SUBTITLE */}
          <StyledTitle variant="heading2" marginBottom="1x">
            ¡Queremos conocerte!
          </StyledTitle>
          <StyledTitle variant="heading2" marginBottom="6x">
            <StyledTitle tag="span" variant="heading2" color="primary">
              Elige las frases
            </StyledTitle>
            {' '}
            que mejor te representan
          </StyledTitle>

          {/* OPTIONS */}
          <Box
            role="group"
            aria-labelledby="checkbox-group"
          >
            <StyledCheckbox
              id="credit-card-without-annuity"
              name="credit-card-without-annuity"
              label="“Quiero una tarjeta de crédito sin anualidad ni cargos sorpresa.”"
              active={currentStepValues['credit-card-without-annuity']}
            />
            <StyledCheckbox
              id="liquidity-savings"
              name="liquidity-savings"
              label="“Quiero ahorrar sin saldo mínimo y con mi dinero  disponible 24/7.”"
              active={currentStepValues['liquidity-savings']}
            />
            <StyledCheckbox
              id="build-credit-history"
              name="build-credit-history"
              label="“Aún no tengo una tarjeta de crédito y quiero construir mi historial.”"
              active={currentStepValues['build-credit-history']}
              disabled={currentStepValues['improve-credit-history']}
            />
            <StyledCheckbox
              id="improve-credit-history"
              name="improve-credit-history"
              label="“He tenido malas experiencias y quiero mejorar mi historial crediticio”."
              active={currentStepValues['improve-credit-history']}
              disabled={currentStepValues['build-credit-history']}
            />
          </Box>

          {/* BUTTON */}
          <Box
            display="flex"
            justifyContent="flex-end"
            marginTop="12x"
          >
            <Button
              id="financial-goals-step-submit-btn"
              variant="contained"
              styleVariant="primary"
              type="submit"
              iconProps={{ name: 'arrow-right', title: 'Siguiente' }}
              disabled={!currentStepValues['credit-card-without-annuity'] && !currentStepValues['liquidity-savings']
              && !currentStepValues['build-credit-history'] && !currentStepValues['improve-credit-history']}
            />
          </Box>

        </Box>
      )}
    </Form.Step>

  );
}

export default FinancialGoals;
