import styled from 'styled-components';

const StyledBox = styled.div` 
  width: 100%;
  position: relative;
  border-bottom: 1px #EFEFEF solid;
  font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
  margin-top: 44px;
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    width: 100%;
    border: none;
    outline: none;
    padding: 12px 0;
    color: #000000F5;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 24px */
    letter-spacing: -0.4px;
    font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
    padding-bottom: 12px;

    &:disabled {
      background-color: transparent;
    }
  }

  & label {
    position: absolute;
    left: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; /* 24px */
    letter-spacing: -0.4px;
    top: 8px;
    pointer-events: none;
    transition: 0.2s;
    color: #00000052;
    font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
  }


  input:focus + label, input:not(:placeholder-shown) + label {
    top: -15px;
    color: #000000A3;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.14px;
    caret-color: #820AD1;
    font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
  }
`;

export default StyledBox;
