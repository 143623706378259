import React, { useEffect } from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled from 'styled-components';
import Link from '@nubank/nuds-web/components/Link/Link';

import { useSiteContext } from '../../../../../../components/SiteContext/SiteContext';
import { FormStepContainer } from '../../../../styles/FormStepContainer';
import {
  approvedScreenRegisterViewEvent, creditCardRTREvent, downloadAppBtnLP,
} from '../../../../tracking';
import AppStoreBadge from '../../../../../../components/AppStoreBadge/AppStoreBadge';
import { useDynamicYieldValues } from '../../../../../../utils/dynamicYield';
import { StyledHeader } from '../../styles/Header';

const StyledTitle = styled(Typography)`
  font-size: 24px;

  @media (width >= 768px) {
    font-size: 1.8rem;
  }
`;

const StyledAppButtonsContainer = styled(Box)`
  gap: 20px;
`;

const ProductBox = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  background: #F6ECFF;
  margin-bottom: 12px;
  padding: 12px 8px;

`;

const Line = styled(Box)`
  border: 1px solid rgba(17 17 17 / 10%);
  margin-top: 5px;
  width: 97%;
`;

const LinksContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;

  a {
    margin-bottom: 12px;
  }
`;

function MultiproductXp() {
  const { closeRegistrationForm } = useSiteContext();
  const { values: dynamicYieldValues } = useDynamicYieldValues();

  useEffect(() => {
    approvedScreenRegisterViewEvent();
    creditCardRTREvent();
  }, []);

  return (
    <FormStepContainer height="100%">
      <StyledHeader>
        <Button
          variant="basic"
          onClick={() => closeRegistrationForm(true)}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
        />
      </StyledHeader>

      {/* TITLE AND SUBTITLE */}
      <StyledTitle
        variant="heading2"
        marginBottom="4x"
      >
        Tu tarjeta de crédito y Cuenta Nu ya están listas para que las contrates 🥳️
      </StyledTitle>

      <Typography
        variant="subtitle1"
      >
        Todo está listo para que
        <Typography
          strong
          variant="subtitle1"
          tag="span"
          color="primary"
        >
          {' '}
          continúes en la app con el mismo correo y el número celular
          {' '}
        </Typography>
        que nos diste en tu registro.
      </Typography>

      {/* APPSTORE BUTTONS */}
      <StyledAppButtonsContainer
        display="flex"
        flexDirection="row"
        marginBottom="4x"
        marginTop="4x"
      >
        <a
          target="__blank"
          href="https://link.nubank.com.br/5YMt1FwBMBb"
          onClick={() => downloadAppBtnLP('ios', 'approvedScreen')}
        >
          <AppStoreBadge platform="ios" variant="light" />
        </a>
        <a
          target="__blank"
          href="https://play.google.com/store/apps/details?id=com.nu.production&listing=ric"
          onClick={() => downloadAppBtnLP('android', 'approvedScreen')}
        >
          <AppStoreBadge platform="android" variant="light" />
        </a>
      </StyledAppButtonsContainer>

      {/* CARDS ROWS */}
      <Box
        marginBottom={{
          xs: '1x',
          md: '1x',
          lg: '3.25rem',
        }}
        paddingHorizontal={{
          xs: '0',
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
        >
          {/* CREDIT CARD + CUENTA ROW */}
          <ProductBox>
            <Image
              alt="Icono de una tarjeta"
              src="one-step/rtr/credit-pre-ric.svg"
              webp={false}
              width="120px"
            />
            <Box
              paddingRight="4x"
              width="100%"
            >
              <Typography
                variant="paragraph1"
                whiteSpace="pre-line"
                marginLeft="2x"
                strong
              >
                Tarjeta de crédito
              </Typography>
              <Typography
                variant="paragraph1"
                whiteSpace="pre-line"
                marginLeft="2x"
              >
                {'$0 anualidad, MSI y \n opciones de pago flexibles.'}
              </Typography>
            </Box>
          </ProductBox>

          {/* CUENTA ROW */}
          <ProductBox>
            <Image
              alt="Icono de una tarjeta"
              src="one-step/rtr/debit-pre-ric.svg"
              webp={false}
              width="120px"
            />
            <Box
              paddingRight="4x"
              width="100%"
            >
              <Typography
                variant="paragraph1"
                marginLeft="2x"
                marginBottom="2x"
                strong
              >
                Cuenta Nu:

                <Typography
                  tag="span"
                  variant="paragraph1"
                  marginLeft="2x"
                >
                  Débito y en la app puedes crecer tus ahorros
                  {' '}
                  <Typography
                    tag="span"
                    variant="paragraph1"
                    strong
                  >
                    {dynamicYieldValues.dynamicYield}
                    % al año
                  </Typography>
                </Typography>
              </Typography>
            </Box>
          </ProductBox>

          {/* BENEFITS */}
          <Box marginTop="3x">
            <Typography variant="heading4" strong>
              Qué beneficios te da Nu:
            </Typography>

            <Line tag="hr" />

            <Box
              marginTop="4x"
              display="flex"
              flexDirection="row"
            >
              <Image
                alt="Icono de corazón"
                src="one-step/rtr/heart.svg"
                webp={false}
                width="24px"
              />
              <Typography
                variant="paragraph1"
                marginLeft="4x"
                marginTop="-5px"
              >
                <Typography tag="span" strong variant="paragraph1">
                  $ 0 anualidad
                </Typography>
                {' '}
                ni comisiones por uso mínimo.
              </Typography>
            </Box>
            <Box
              marginTop="4x"
              display="flex"
              flexDirection="row"
            >
              <Image
                alt="Icono de escudo"
                src="one-step/rtr/shield.svg"
                webp={false}
                width="34px"
              />
              <Typography
                variant="paragraph1"
                marginLeft="4x"
              >
                Tecnología para
                <Typography tag="span" strong variant="paragraph1">
                  {' '}
                  cuidar que solo tú apruebes
                  {' '}
                </Typography>
                y sepas de cada transacción.
              </Typography>
            </Box>

          </Box>

          <LinksContainer>
            <Link
              href="/cat"
              variant="action"
              typographyProps={{ variant: 'paragraph1' }}
              target="_blank"
            >
              CAT de Tarjeta de Crédito Nu
            </Link>

            <Link
              href="/gat"
              variant="action"
              typographyProps={{ variant: 'paragraph1' }}
              target="_blank"
            >
              GAT Nominal y Real de Cuenta Nu
            </Link>
          </LinksContainer>
        </Box>
      </Box>
    </FormStepContainer>
  );
}

export default MultiproductXp;
