import React, { useEffect, useRef, useState } from 'react';
import Form from '@nubank/nuds-web/components/Form/Form';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Box from '@nubank/nuds-web/components/Box/Box';
import Image from '@nubank/nuds-web/components/Image/Image';
import styled from 'styled-components';
import Snackbar from '@nubank/nuds-web/components/Snackbar/Snackbar';

import { ERROR_SEVERITY, sentryException } from '../../../../../utils/sentry';
import { get as getCaptcha } from '../../../../../utils/recaptcha';
import {
  personalInfoRegisterEvent, curpFeedbackEvent,
} from '../../../tracking';
import { formatDOB } from '../../../../../utils/form/formatDOB';
import { sendCandidateDocuments } from '../../../../../domains/candidate/candidateDocuments';
import { validateCURP } from '../../../../../domains/candidate/candidateValidation';
import { useSiteContext } from '../../../../../components/SiteContext/SiteContext';
import { useRegistrationFormContext } from '../../RegistrationForm/RegistrationForm';
import Spinner from '../../../../../components/Spinner/Spinner';
import CurpAndRfcGeneratorExperiment from '../components/CurpAndRfcGeneratorExperiment';
import DatePicker from '../components/DatePicker';
import CountryPicker from '../components/CountryPicker';
import CheckboxFeedback from '../components/StyledCheckboxFeedback';
import GenderInput from '../components/GenderInput';

const StyledTitle = styled(Typography)`
  font-size: 2rem;

  @media (width >= 768px) {
    font-size: 2.5rem;
  }
`;

const StyledSubtitle = styled(Typography)`
  span {
    color: #820AD1;
    font-weight: bold;
  }
`;

const StyledRFCTitle = styled(Typography)`
  font-size: 2rem;

  span {
    color: #000000F5;
  }
`;

const StyledSection = styled(Box)`
  button {
    min-width: 64px;
    height: 64px;
    margin-left: 18px;
  }

  span {
    color: #820AD1;
  }
`;

const StyledImage = styled(Image)`
  margin-top: -24px;
`;

function CurpFeedback() {
  // Timeout for the RENAPO response xp
  const timeout = 10;

  // Forbidden by reCaptcha message
  const formErrorMsg = useState('Ocurrió un error, por favor intenta de nuevo, si el problema persiste, por favor contacta a soporte');
  const [displayFormErrorMsg, setDisplayFormErrorMsg] = useState(false);

  const namesInputRef = useRef();
  const {
    registrationFlowEvents, updateRegistrationFlowEvents, discoveryUrlsList,
  } = useSiteContext();
  const [validateWithRenapo, setValidateWithRenapo] = useState(true);
  const { setUncheckPersonalInfoBox } = useRegistrationFormContext();
  const [invalidCURP, setInvalidCURP] = useState(false);
  const [invalidTaxId, setInvalidTaxId] = useState(false);
  const [sentCURP, setSentCURP] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [recalculatedTaxId, setRecalculatedTaxId] = useState('');
  const [sendEventForTaxId, setSendEventForTaxId] = useState(true);

  const errorHandler = error => sentryException({
    error,
    flow: 'application_flow',
    checkpoint: 'sendCandidateDocuments',
    namespace: '<PersonalInfoStep />',
    level: ERROR_SEVERITY.CRITICAL,
  });

  const finalFlow = async (nextStep, setSubmitting, values) => {
    setValidateWithRenapo(false);
    const { day, month, year } = values;
    const formattedDOB = formatDOB(day, month, year);

    try {
      await sendCandidateDocuments(values.curp, values.taxId, discoveryUrlsList);
    } catch (error) {
      errorHandler(error);
    }

    if (!registrationFlowEvents.personalInformation) {
      personalInfoRegisterEvent(formattedDOB);
      updateRegistrationFlowEvents({ ...registrationFlowEvents, personalInformation: true });
    }
    setSubmitting(false);
    nextStep();
  };

  const validationFlow = (response, values, nextStep, setSubmitting) => {
    // Forbidden by reCaptcha
    if (response?.response?.status && response.response.status === 403) {
      setDisplayFormErrorMsg(true);
      setIsLoading(false);
      return;
    }

    // *** RENAPO IS DOWN OR IT TOOK MORE THAN 5 SECONDS
    if (Object.keys(response).length === 0) {
      // We let the user continue with the flow
      curpFeedbackEvent('timeout');
      finalFlow(nextStep, setSubmitting, values);
    }

    // *** RENAPO IS UP
    if (Object.keys(response).length > 0) {
      // CURP is invalid
      if (!response.valid) {
        setInvalidCURP(true);
        setIsLoading(false);
        curpFeedbackEvent('curp invalid');
      }

      // CURP is valid but the prospect already went through the flow
      // and we are not retrieving his RFC from INCODE cause it's already in the database
      if (response.valid && !response.tax_id) {
        curpFeedbackEvent('curp valid');
        curpFeedbackEvent('old data');
        finalFlow(nextStep, setSubmitting, values);
      }

      // CURP is valid and we are retrieving the RFC from INCODE
      if (response.valid && response.tax_id) {
        // The RFC matches the one from the UI
        if (values.taxId === response.tax_id) {
          curpFeedbackEvent('curp valid');
          finalFlow(nextStep, setSubmitting, values);
        } else {
          // The RFC doesn't match the one from the UI
          setRecalculatedTaxId(response.tax_id);
          setInvalidTaxId(true);
          setValidateWithRenapo(false);
          setIsLoading(false);
          if (sendEventForTaxId) {
            curpFeedbackEvent('rfc invalid');
            setSendEventForTaxId(false);
          }
        }
      }
    }
  };

  // Call to validate the CURP
  let apiResponse = false;
  async function curpValidationCall(curp, captcha) {
    apiResponse = false;
    const response = await validateCURP(curp, discoveryUrlsList, captcha);
    apiResponse = response;
    return apiResponse;
  }

  // Timer to wait only 5s for the response from RENAPO
  const timer = (values, nextStep, setSubmitting, captcha) => {
    curpValidationCall(values.curp?.toUpperCase(), captcha);
    let count = 0;
    const timeInterval = setInterval(() => {
      count += 0.5;

      if (count < timeout && apiResponse) {
        validationFlow(apiResponse, values, nextStep, setSubmitting, count);
        clearInterval(timeInterval);
      }

      if (count >= timeout) {
        clearInterval(timeInterval);
        validationFlow({}, values, nextStep, setSubmitting, count);
      }
    }, 500);
    timeInterval();
  };

  // Click on submit btn
  const handleSubmit = async ({ nextStep, setSubmitting, values }) => {
    setUncheckPersonalInfoBox(true);
    setIsLoading(true);

    const curpWasUpdated = () => {
      // If the user updates the CURP, we validate again
      if (sentCURP !== values.curp) {
        setValidateWithRenapo(true);
      } else {
        // If they don't change the CURP, we let them pass
        setValidateWithRenapo(false);
        finalFlow(nextStep, setSubmitting, values);
      }
    };

    // We already did the validation and the CURP is invalid
    if (invalidCURP && !invalidTaxId) {
      curpWasUpdated();
    }

    // We did the validation and the CURP is valid but RFC doesn't match the CURP
    if (invalidTaxId && !invalidCURP) {
      // We already updated the RFC value to match the CURP
      // The user updated or kept our updated RFC so we let them pass
      setValidateWithRenapo(false);
      finalFlow(nextStep, setSubmitting, values);
    }

    setSentCURP(values.curp);
    setInvalidTaxId(false);

    if (validateWithRenapo) {
      setInvalidCURP(false);
      setUncheckPersonalInfoBox(true);
      const captcha = await getCaptcha();
      timer(values, nextStep, setSubmitting, captcha);
    }
  };

  useEffect(() => {
    namesInputRef.current?.focus();
    curpFeedbackEvent('displayed');
  }, []);

  return (
    <Form.Step
      initialValues={{
        names: '',
        firstSurname: '',
        secondSurname: '',
        gender: '',
        day: '',
        month: '',
        year: '',
        countryOfBirth: 'MEX',
        entity: '',
        curp: '',
        taxId: '',
        accepted: false,
      }}
      initialTouched={{ countryOfBirth: true }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        isDisabled,
        currentStepValues,
        setFieldValue,
        isSubmitting,
        formsValues,
      }) => (
        <Box
          overflowY="auto"
          paddingTop={{ xs: '24px', lg: '40px' }}
          paddingBottom="4x"
          paddingHorizontal={{ xs: '24px', lg: '40px' }}
          position="relative"
          display="flex"
          flexDirection="column"
          minHeight="100%"
        >

          <StyledTitle
            variant="heading3"
            marginBottom="4x"
          >
            Ahora, necesitamos tus datos personales
          </StyledTitle>

          <StyledSubtitle variant="subtitle1" marginBottom="4x">
            <span>No uses abreviaturas</span>
            {' '}
            y cuida que estén escritos
            <span> igual que en tu identificación oficial.</span>
          </StyledSubtitle>

          <TextField
            id="names"
            name="names"
            ref={namesInputRef}
            label="Nombre(s)"
            syncValidations={{
              required: 'Por favor revisa que tu(s) nombre(s) sea correcto',
              names: 'Por favor revisa que tu(s) nombre(s) sea correcto',
            }}
          />

          <TextField
            id="firstSurname"
            name="firstSurname"
            label="Primer apellido"
            syncValidations={{
              required: 'Por favor revisa que tu primer apellido sea correcto',
              firstSurname: 'Por favor revisa que tu primer apellido sea correcto',
            }}
          />

          <TextField
            id="secondSurname"
            name="secondSurname"
            label="Segundo apellido"
            syncValidations={{
              secondSurname: 'Por favor revisa que tu segundo apellido sea correcto',
            }}
          />

          <GenderInput />

          {/* START FIELDS FROM PERSONAL INFO 2 */}

          <Typography
            variant="paragraph2"
            strong
            colorVariant="defaultT70"
            color="black"
            marginTop="8x"
          >
            Fecha de nacimiento
          </Typography>

          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            gridColumnGap="6x"
            paddingBottom="4x"
          >
            <DatePicker
              selectedMonth={currentStepValues.month}
              selectedYear={currentStepValues.year}
              setFieldValue={setFieldValue}
            />
          </Box>

          <CountryPicker setFieldValue={setFieldValue} />

          <Box
            backgroundColor="white.dark"
            borderRadius="24px 24px 0px 0px"
            marginHorizontal={{ xs: '-24px', lg: '-40px' }}
            marginTop="6x"
            marginBottom="2x"
            paddingTop="40px"
            paddingHorizontal={{ xs: '6x', lg: '40px' }}
            height="100%"
            display="flex"
            flexDirection="column"
          >

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              paddingBottom="32px"
            >
              <StyledRFCTitle
                color="primary.default"
                variant="heading3"
                strong
                whiteSpace="pre-line"
              >
                Revisa muy bien
                {' \n'}
                <span>tu CURP y RFC</span>
              </StyledRFCTitle>

              <StyledImage
                src="one-step/doc-success.svg"
                alt="Logo compartir"
                webp={false}
              />

            </Box>

            <StyledSubtitle marginBottom="2x">
              Para continuar tu solicitud y validar tu identidad, debes
              {' '}
              <span>cuidar que tus datos son correctos.</span>
            </StyledSubtitle>

            <CurpAndRfcGeneratorExperiment
              currentStepValues={{ ...currentStepValues, ...formsValues }}
              setFieldValue={setFieldValue}
              invalidCURP={invalidCURP}
              isLoading={isLoading}
              invalidTaxId={invalidTaxId}
              recalculatedTaxId={recalculatedTaxId}
            />

            <CheckboxFeedback
              setFieldValue={setFieldValue}
              values={formsValues}
              currentStepValues={currentStepValues}
            />

          </Box>

          {/* ENDS FIELDS FROM PERSONAL INFO 2 */}
          <StyledSection
            display="flex"
            alignItems="center"
            marginTop="40px"
            marginBottom="4x"
          >
            <Typography
              variant="paragraph2"
              strong
              marginBottom="4x"
            >
              Considera que solo preguntamos tu género únicamente para calcular tu CURP.
            </Typography>

            { isLoading
              ? (
                <Button
                  id="loading-btn"
                  variant="contained"
                  styleVariant="primary"
                  type="submit"
                  disabled
                >
                  <Spinner color="#820AD1" />
                </Button>
              )
              : (
                <Button
                  id="personalinfo-step-submit-btn"
                  variant="contained"
                  styleVariant="primary"
                  type="submit"
                  iconProps={{ name: 'arrow-right', title: 'Buscar Código Postal' }}
                  disabled={isDisabled || isSubmitting || !currentStepValues.accepted}
                />
              ) }
          </StyledSection>
          {/* Error message */}
          <Snackbar
            visible={displayFormErrorMsg}
            onActionClick={() => setDisplayFormErrorMsg(false)}
            actionText="Cerrar"
          >
            {formErrorMsg}
          </Snackbar>
        </Box>
      )}
    </Form.Step>
  );
}

export default CurpFeedback;
