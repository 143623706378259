import React from 'react';

import { StyledSVG } from './styles/StyledSVG';

const MexicoFlag = () => (
  <StyledSVG width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#006847" d="M0 0h8v24H0z" />
    <path fill="#fff" d="M8 0h8v24H8z" />
    <path fill="#CE1126" d="M16 0h8v24h-8z" />
    <circle cx="12" cy="12" r="2" fill="#D69651" />
  </StyledSVG>

);

export default MexicoFlag;
