import React from 'react';
import PropTypes from 'prop-types';

import ProgressBarContainer from './styles/ProgressBarContainer';
import Filler from './styles/Filler';

const ProgressBar = ({ percentage, height, borderRadius }) => (
  <ProgressBarContainer
    height={height}
    borderRadius={borderRadius}
  >
    <Filler
      role="progressbar"
      data-testid="progress-filler"
      id="progress-filler"
      width={percentage > 100 ? 100 : percentage}
    />
  </ProgressBarContainer>
);

ProgressBar.defaultProps = {
  percentage: 10,
  height: '1rem',
  borderRadius: '0',
};

ProgressBar.propTypes = {
  borderRadius: PropTypes.string,
  height: PropTypes.string,
  percentage: PropTypes.number,
};

export default ProgressBar;
